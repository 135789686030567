<template>
    <button v-show="showMe" class="btn" @click="handleAddButtonClick">
        <img class="soft-img" src="/images/icons/icon_addcircle_outline.svg" alt="" role="img" aria-hidden="true" />
        <label>{{ $t('editButtonOrderPageAddItem') }}</label>
    </button>
</template>
<script>
import editButtonsMixin from '../mixins/editButtons'

export default {
    props: ['showMe'],
    mixins: [editButtonsMixin],
};
</script>
