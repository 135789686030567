<template>
	<div id="ar-content" class="container-fluid">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'Content',
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {}
};
</script>

<style scoped>
</style>
