const iconPath = '/images/icons';
export const flexApps = [
    {
        label: 'Live TV',
        command: 'live tv',
        thumbnail: `${iconPath}/liveTv.svg`,
        isSvgIcon: true,
    },
    {
        label: 'Page up',
        command: 'page up',
        thumbnail: `${iconPath}/pageUp.svg`,
        isSvgIcon: true,
    },
    {
        label: 'Page down',
        command: 'page down',
        thumbnail: `${iconPath}/pageDown.svg`,
        isSvgIcon: true,
    },
    {
        label: 'YouTube',
        command: 'youtube',
        thumbnail: `${iconPath}/youtube.svg`,
    },
    {
        label: 'Netflix',
        command: 'netflix',
        thumbnail: `${iconPath}/netflix.svg`,
    },
    {
        label: 'Hulu',
        command: 'hulu',
        thumbnail: `${iconPath}/hulu.svg`,
    },
    {
        label: 'Disney+',
        command: 'disney plus',
        thumbnail: `${iconPath}/disneyPlus.svg`,
    },
    {
        label: 'Peacock',
        command: 'peacock',
        thumbnail: `${iconPath}/peacock.svg`,
    },
    {
        label: 'HBO Max',
        command: 'hbo max',
        thumbnail: `${iconPath}/hboMax.svg`,
    },
    {
        label: 'Tubi',
        command: 'tubi',
        thumbnail: `${iconPath}/tubi.svg`,
    },
    {
        label: 'Paramount+',
        command: 'paramount plus',
        thumbnail: `${iconPath}/paramountPlus.svg`,
    },
    {
        label: 'Prime Video',
        command: 'prime video',
        thumbnail: `${iconPath}/primeVideo.svg`,
    },
    {
        label: 'Spotify',
        command: 'spotify',
        thumbnail: `${iconPath}/spotify.svg`,
    },
    {
        label: 'Pandora',
        command: 'pandora',
        thumbnail: `${iconPath}/pandora.svg`,
    },
    {
        label: 'App menu',
        command: 'app menu',
        thumbnail: `${iconPath}/appMenu.svg`,
        isSvgIcon: true,
    },
];
