import { Analytics } from '../../analytics.js';

export default {
	data: function () {
		ar.state.soundsAudio = [
			{
				id: 'sound-up',
				src: '/sounds/Xfinity_Up_Mobile_ST-20LKFS.wav'
			},
			{
				id: 'sound-down',
				src: '/sounds/Xfinity_Down_Mobile_ST-20LKFS.wav'
			},
			{
				id: 'sound-left-or-right',
				src: '/sounds/Xfinity_Side_Mobile_ST-20LKFS.wav'
			},
			{
				id: 'sound-select',
				src: '/sounds/Xfinity_Select_Tap_Mobile_ST-20LKFS.wav'
			},
			{
				id: 'sound-confirm',
				src: '/sounds/Xfinity_Confirm_Mobile_ST-20LKFS.wav'
			},
			{
				id: 'sound-listening',
				src: '/sounds/listening.wav'
			},
			{
				id: 'sound-listening-complete',
				src: '/sounds/listening_complete.wav'
			}
		];
		return ar.state;
	},
	methods: {
		click: function (event) {
			// WARNING: DOM-Fragile!
			var target, classList, elem, dataset, command, vcode, id;
			target = event.target;
			classList = target.classList;
			if (!classList.contains('btn')) {
				elem = target.parentElement;
				// Handle click on paths, etc. within svgs
				if (elem.nodeName === 'svg') {
					elem = elem.parentElement;
				}
				if (elem.disabled) {
					// Drop click event
					return;
				}
			} else if (classList.contains('btn')) {
				elem = target;
				if (elem.disabled) {
					// Drop click event
					return;
				}
			} else {
				// Drop click event
				return;
			}

			dataset = elem.dataset;
			command = dataset.command;
			vcode = dataset.vcode;
			id = elem.id;
			if (command) {
				// "Flash" the button to show it was clicked
				elem.classList.add('flash');
				setTimeout(function () {
					elem.classList.remove('flash');
				}, 125);
				// Beep if sound is on
				if (ar.state.keySound) {
					ar.beep();
				}
				// Process the key
				Analytics.track({ eventName: 'custom_button', properties: { command: command } });
				ar.submitTextCommand(command);
			}
			if (vcode) {
				if (vcode === 'VOICE') {
					// "Flash" the button to show it was clicked
					elem.classList.add('flash');
					setTimeout(function () {
						elem.classList.remove('flash');
					}, 125);
					if (!ar.recorder.isRecording()) {
						if (ar.state.keySound) {
							ar.sound('START-LISTENING');
						}

						// Start Recording
						Analytics.track({ eventName: 'start_recording' });
						ar.recorder.start();
						// Update UI (via reactivity)
						ar.setState({
							isRecording: true
						});
					} else {
						if (ar.state.keySound) {
							ar.sound('STOP-LISTENING');
						}

						// Stop Recording
						Analytics.track({ eventName: 'stop_recording' });
						ar.recorder.stop(function (err, url) {
							// @TODO: check err
							ar.setState({
								isRecording: false
							});
							ar.postStopRecording(null, url);
						});
					}
				} else {
					// "Flash" the button to show it was clicked
					elem.classList.add('flash');
					setTimeout(function () {
						elem.classList.remove('flash');
					}, 125);

					// Process the button (handle CEC buttons differently than regular buttons)
					const tvButton = ar.tvButtons().find((button) => button.id === id);
					if (tvButton) {
						Analytics.track({ eventName: 'tv_button', properties: { key: tvButton.key } });
						ar.processTvKey(
							tvButton.key,
							tvButton.cecKey,
							tvButton.cecValue /*@TODO: TOGGLE!*/
						);
					} else {
						if (ar.state.keySound) {
							ar.sound(vcode);
						}
						Analytics.track({ eventName: 'stb_button', properties: { vcode: vcode } });
						ar.processKey(vcode);
					}
				}
			}
		},
		closeMenu: function () {
			if (this.menuOpen) {
				this.menuOpen = false;
			}
		}
	},

	computed: {
		ariaHidden: function () {
			if (ar.state.menuOpen) {
				return true;
			} else {
				return false;
			}
		},

		enableTvButtons: function () {
			return ar.state.tvConfigEnabled && ar.getTvConfigData(ar.enableTvButtons);
		}
	},

	mounted: function () {
		//@TODO: ar.enableCecButtons();
		ar.state.tvConfigEnabled && ar.getTvConfigData(ar.enableTvButtons);
	}
};
