<template>
    <div id="button-sections" :key="enableTvButtons">
        <div class="button-section">
            <div class="button-row">
                <TVPower></TVPower>
                <TVInput></TVInput>
                <AllPower></AllPower>
            </div>
            <div class="button-row">
                <Mute></Mute>
                <VolumeDown></VolumeDown>
                <VolumeUp></VolumeUp>
            </div>
            <div class="button-row">
                <Rewind></Rewind>
                <PlayPause></PlayPause>
                <FastForward></FastForward>
            </div>
            <div class="button-row">
                <Accessibility></Accessibility>
                <Info></Info>
                <Search></Search>
            </div>
            <div class="button-row">
                <Voice></Voice>
                <Prev></Prev>
                <Home></Home>
            </div>
            <VoiceCommand></VoiceCommand>
        </div>

        <div class="button-section">
            <div class="flex-app-button-group">
                <button
                    v-for="button in getFlexCategories()"
                    :key="button.label"
                    :id="button.label"
                    :name="button.label"
                    class="btn"
                    :aria-label="button.command"
                    :data-command="button.command"
                >
                    <img :class="{ 'svg-icon': button.isSvgIcon }" :src="button.thumbnail" />
                    <label>{{ button.label }}</label>
                </button>
            </div>
            <DirPad></DirPad>
        </div>

        <div class="button-section">
            <div class="flex-app-button-group">
                <button
                    v-for="button in getFlexApps()"
                    :key="button.label"
                    :id="button.label"
                    :name="button.label"
                    class="btn"
                    :aria-label="button.command"
                    :data-command="button.command"
                >
                    <img :class="{ 'svg-icon': button.isSvgIcon }" :src="button.thumbnail" />
                    <label>{{ button.label }}</label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import VoiceCommand from './VoiceCommand.vue';
import remoteMixin from './mixins/remote';
/* Buttons */
import AllPower from './buttons/AllPower.vue';
import DirPad from './buttons/DirPad.vue';
import FastForward from './buttons/FastForward.vue';
import Mute from './buttons/Mute.vue';
import PlayPause from './buttons/PlayPause.vue';
import Rewind from './buttons/Rewind.vue';
import TVInput from './buttons/TVInput.vue';
import TVPower from './buttons/TVPower.vue';
import Voice from './buttons/Voice.vue';
import VolumeDown from './buttons/VolumeDown.vue';
import VolumeUp from './buttons/VolumeUp.vue';
import Home from './buttons/Home.vue';
import Prev from './buttons/Prev.vue';
import Info from './buttons/Info.vue';
import Shortcut from './buttons/Shortcut.vue';
import Search from './buttons/Search.vue';
import { flexApps } from '../js/flex-apps';
import { flexCategories } from '../js/flex-categories';
import Accessibility from './buttons/Accessibility.vue';

export default {
    name: 'FlexRemote',
    mixins: [remoteMixin],
    components: {
        AllPower,
        DirPad,
        FastForward,
        Mute,
        PlayPause,
        Rewind,
        TVInput,
        TVPower,
        Voice,
        VoiceCommand,
        VolumeDown,
        VolumeUp,
        Home,
        Prev,
        Info,
        Shortcut,
        Search,
        Accessibility,
    },
    methods: {
        getFlexApps: function () {
            return flexApps || [];
        },
        getFlexCategories: function () {
            return flexCategories || [];
        },
    },
};
</script>

<style lang="scss" scoped>
.flex-app-button-group {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto auto auto;
    margin-bottom: 0.5rem;
}
</style>
