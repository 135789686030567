const iconPath = '/images/icons';
export const flexCategories = [
    {
        label: 'My list',
        command: 'my list',
        thumbnail: `${iconPath}/myList.svg`,
        isSvgIcon: true,
    },
    {
        label: 'TV shows',
        command: 'tv shows',
        thumbnail: `${iconPath}/tvShows.svg`,
        isSvgIcon: true,
    },
    {
        label: 'Movies',
        command: 'movies',
        thumbnail: `${iconPath}/movies.svg`,
        isSvgIcon: true,
    },
    {
        label: 'Sports',
        command: 'sports',
        thumbnail: `${iconPath}/sports.svg`,
        isSvgIcon: true,
    },
    {
        label: 'Music',
        command: 'music',
        thumbnail: `${iconPath}/music.svg`,
        isSvgIcon: true,
    },
    {
        label: 'News',
        command: 'news',
        thumbnail: `${iconPath}/news.svg`,
        isSvgIcon: true,
    },
];
