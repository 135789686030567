<template>
	<div class="menu-wrapper" v-bind:class="{ active: menuOpen, menuPartnerComcast: isComcast }" id="menu">
		<button v-on:click="toggleMenu" class="menu-button" id="menu_btn" :aria-label="$t('menuLabelApp')"
			v-bind:aria-expanded="ariaExpand">
			<svg viewBox="0 0 80 80" width="32" height="32" aria-hidden="true">
				<g>
					<rect width="80" height="10" rx="6"></rect>
					<rect y="30" width="80" height="10" rx="6"></rect>
					<rect y="60" width="80" height="10" rx="6"></rect>
				</g>
			</svg>
		</button>
		<div id="menu_container" class="menu-container">
			<template v-if="menuOpen">
				<ul id="button-bar-and-info" class="menu">
					<li class="item">
						<a v-on:click.prevent="handleTvBox" href="#">{{ $t('changeBox') }}</a>
					</li>
					<template v-if="analyticsEnabled">
						<li class="item">
							<a v-on:click.prevent="gotoSettingsPage" href="#">{{ $t('settings') }}</a>
						</li>
					</template>
					<li class="item">
						<label class="toggle-switch" for="sound">
							{{ $t('soundCues') }}
							<input type="checkbox" id="sound" v-bind:checked="keySound" v-on:click="toggleKeySound">
							<span class="toggle-bar round"></span>
						</label>
					</li>
					<template v-if="shortcutLink">
						<li class="item">
							<a id="bookmark" v-bind:href="shortcutLink">
								<img src="/images/toolbar/icon-bookmark.svg" class="toolbar-image" width="16"
									height="16" v-bind:alt="$t('bookmark')" v-bind:title="$t('bookmark')" />
							</a>
						</li>
					</template>
					<li class="item">
						<template v-if="myRemoteRoute">
							<a id="standardRemote" v-on:click.prevent="gotoStandardRemotePage" href="#">
								{{ $t('standardRemote') }}
							</a>
						</template>
						<template v-else>
							<a id="seabuttons" v-on:click.prevent="gotoSeaButtonsPage" href="#">
								{{ $t('myRemote') }} <img class="beta-badge" src="/images/beta-badge@2x.png" alt="beta" />
							</a>
						</template>
					</li>
					<template v-if="myRemoteRoute">
						<li class="item">
							<a id="myRemoteConfig" v-on:click.prevent="goToSeaButtonConfigPage" href="#">
								{{ $t('configMyRemote') }} <img class="beta-badge" src="/images/beta-badge@2x.png"
									alt="beta" />
							</a>
						</li>
					</template>
					<template v-if="isTvConfigEnabled">
						<li class="item">
							<a id="tvConfigEvent" v-on:click.prevent="goToTvConfigPage" href="#">
								{{ $t('configTvButtons') }}
							</a>
						</li>
					</template>
					<template v-if="isComcast">
						<li class="item">
							<a id="learnMore" v-on:click.prevent="doLearnMore" href="#">
								{{ $t('learnMore') }}
							</a>
						</li>
					</template>
					<li class="item">
						<a id="logout" v-bind:href="logoutLink">
							{{ $t('logout') }}
						</a>
					</li>
				</ul>
			</template>
			<template v-if="menuOpen">
				<div class="menu-footer">
					<div class="version-info" v-bind:class="{ fontPartnerComcast: isComcast }">
						<p>{{ $t('version') }} {{ version }}</p>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { Analytics } from '../analytics.js';

export default {
	name: 'Menu',
	components: {
	},
	props: ['shortcutLink'],
	data: function () {
		return ar.state
	},
	methods: {
		gotoSettingsPage: function() {
			Analytics.track({ eventName: 'menu_settings', properties: undefined });
			ar.state.menuOpen = false;
			arRouter.push('/settings');
		},
		gotoSeaButtonsPage: function () {
			Analytics.track({ eventName: 'menu_my_remote', properties: undefined });
			ar.state.menuOpen = false;
			arRouter.push('/my-remote');
		},
		gotoStandardRemotePage: function () {
			Analytics.track({ eventName: 'menu_standard_remote', properties: undefined });
			ar.state.menuOpen = false;
			arRouter.push('/remote');
		},
		goToSeaButtonConfigPage: function () {
			Analytics.track({ eventName: 'menu_config_my_remote', properties: undefined });
			ar.state.menuOpen = false;
			arRouter.push('/my-remote-config');
		},
		goToTvConfigPage: function () {
			Analytics.track({ eventName: 'menu_tv_control', properties: undefined });
			ar.state.menuOpen = false;
			arRouter.push('/tv-config');
		},
		toggleMenu: function () {
			if ( this.menuOpen ) {
				Analytics.track({ eventName: 'menu_close', properties: undefined });
			} else {
				Analytics.track({ eventName: 'menu_open', properties: undefined });
			}
			this.menuOpen = !this.menuOpen;
			if (this.menuOpen) {
				const self = this;
				window.setTimeout(function () {
					self.trapFocus();
				}, 0);
			}
		},
		toggleKeySound: function (e) {
			if ( this.keySound ) {
				Analytics.track({ eventName: 'sound_off', properties: undefined });
			} else {
				Analytics.track({ eventName: 'sound_on', properties: undefined });
			}
			ar.setState({
				keySound: !this.keySound
			});
		},
		handleTvBox: function () {
			Analytics.track({ eventName: 'menu_choose_box', properties: undefined });
			arRouter.push('/choose-device');
		},
		trapFocus: function () {
			// add all the elements inside menu which you want to make focusable
			const menu = document.querySelector('#menu'); // select the menu by it's id
			const firstFocusableElement = document.querySelector('#menu_btn'); // get first element to be focused inside menu
			const focusableElements =
				'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

			const focusableContent = menu.querySelectorAll(focusableElements);
			const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside menu

			if (lastFocusableElement === firstFocusableElement) {
				return;
			}

			document.addEventListener('keydown', function (e) {
				let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
				if (!isTabPressed) {
					return;
				}

				if (e.shiftKey) { // if shift key pressed for shift + tab combination
					if (document.activeElement === firstFocusableElement && ar.state.menuOpen) {
						lastFocusableElement.focus(); // add focus for the last focusable element
						e.preventDefault();
					}
				} else { // if tab key is pressed
					if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
						firstFocusableElement.focus(); // add focus for the first focusable element
						e.preventDefault();
					}
				}
			});

			firstFocusableElement.focus();
		},
		doLearnMore: function () {
			Analytics.track({ eventName: 'menu_learn_more', properties: undefined });
			ar.state.menuOpen = false;
			arRouter.push('/learn-more');
		}
	},
	computed: {
		analyticsEnabled: function () {
			return window.arff.analyticsEnabled;
		},
		ariaExpand: function () {
			if (this.menuOpen) {
				return "true";
			} else {
				return "false";
			}
		},
		logoutLink: function () {
			const partner = this.partner || 'comcast';
			const clientDeviceId = this.clientDeviceId;
			let url = '/logout?partner=' + partner + '&clientDeviceId=' + clientDeviceId;
			// In case it is needed for an IDP:
			url += '&idToken=' + this.idToken;
			return url;
		},

		soundAriaChecked: function () {
			if (this.keySound) {
				return "true";
			} else {
				return "false";
			}
		},

		soundToggleValue: function () {
			if (this.keySound) {
				return "On";
			} else {
				return "Off";
			}
		},

		menuLabel: function () {
			return ar.i18nJ.t('menuLabel');
		},

		isComcast: function () {
			return (ar.getCorePartnerFromPartner(this.partner) === 'comcast');
		},

		isTvConfigEnabled: function () {
			return ar.state.tvConfigEnabled;
		},

		myRemoteRoute: function () {
			let currentRoute = this.$route.path;
			return (currentRoute === '/my-remote');
		}
	},

	mounted: function () {
		document.addEventListener('keydown', function (e) {
			if ((e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && ar.state.menuOpen) {
				ar.state.menuOpen = false;
				document.getElementById("menu_btn").focus();
			}
		});
	}
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 21.75rem;
	font-size: 1.5rem;
	font-family: "XfinityStandard", Arial;
	letter-spacing: -0.3px;
	z-index: 999;

	&.active {
		background-color: $ar-background-color;
		height: 100%;
		overflow: scroll;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.5);

		&::-webkit-scrollbar {
			display: none;
		}

		.menu-container {
			display: block;
		}
	}
}

.menuPartnerComcast {
	font-family: 'XfinityBrown-Regular', Arial;

	.menu {
		li {
			&:hover,
			a:hover,
			a:focus,
			&:focus-within {
				font-family: 'XfinityBrown-Bold', Arial;
			}
		}
	}
}

.menu-button {
	background: transparent;
	border: none;
	border-radius: 0.5rem;
	margin-left: 1rem;
	margin-top: 0.5rem;
	width: 3rem;
	height: 3rem;

	@include focus-pseudo-class;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		fill: white;
		margin: 0.625rem auto 2px;

		@include md {
			width: 2rem;
			height: 2rem;
		}
	}

	&:focus {
		background: rgb(236, 236, 242);
	}

	&:hover {
		outline: .25rem solid rgb(236, 236, 242);
	}

	&:focus-within svg {
		fill: black;
	}

	@include md{
		margin-left: 2rem;
		margin-top: 2rem;
		width: 3.5rem;
		height: 3.5rem;
	}
}

.menu-container {
	color: white;
	text-align: left;
	display: none;
}

.menu {
	color: white;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding-left: 0;

	li {
		display: block;
		width: 100%;
		position: relative;

		a {
			display: block;
			padding: 1.5rem 0 1.5rem 3rem;
			position: relative;

			@media screen and (max-width: 35rem) {
				padding-left: 1rem;
			}
		}

		&:hover,
		&:focus-within,
		a:hover,
		a:focus {
			/*  REFERENCE: https://css-tricks.com/almanac/selectors/f/focus-within/*/
			background: white;
			color: black;
			font-family: "XfinityStandard-Bold", Arial;
			outline: 0px solid transparent;
		}
	}
}

.toggle-switch {
	position: relative;
	display: block;
	padding: 1.5rem 0 1.5rem 3rem;

	input[type="checkbox"] {
		opacity: 0;
		width: 0;
		height: 0;
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}

	.toggle-bar {
		position: absolute;
		cursor: pointer;
		top: 1.5rem;
		right: 1.5rem;
		bottom: 0;
		background-color: transparent;
		border: 4px solid white;
		-webkit-transition: .4s;
		transition: .4s;
		width: 58px;
		height: 22px;

		&.round {
			border-radius: 34px;

			&::before {
				border-radius: 50%;
			}
		}

		&::before {
			position: absolute;
			content: "";
			height: 14px;
			width: 14px;
			left: 4px;
			bottom: 2px;
			background-color: transparent;
			border: 2px solid white;
			-webkit-transition: .4s;
			transition: .4s;
		}
	}

	@media screen and (max-width: 35rem) {
		padding-left: 1rem;
	}

}

input[type="checkbox"]:checked+.toggle-bar:before {
	-webkit-transform: translateX(32px);
	-ms-transform: translateX(32px);
	transform: translateX(32px);
	background-color: white;
}

.menu li:hover .toggle-switch .toggle-bar,
.menu li:focus-within .toggle-switch .toggle-bar {
	border: 4px solid black;
}

.menu li:hover .toggle-switch .toggle-bar::before,
.menu li:focus-within .toggle-switch .toggle-bar::before {
	background-color: black;
	border: 2px solid black;
}

.menu-footer {
	padding: 2rem;

	@media screen and (max-width: 35rem) {
		padding-left: 1rem;
	}
}

.version-info {
	color: rgb(177, 185, 191);
	font-size: 1rem;
	font-family: "XfinityStandard-Bold", Arial;
	font-weight: bold;
	height: 24px;
	letter-spacing: 0px;
	line-height: 24px;
	margin-bottom: 1rem;
}

.fontPartnerComcast {
	font-family: "XfinityBrown-Bold", Arial;
}

.beta-badge {
	width: 2rem;
	vertical-align: middle;
	margin-left: 10px;
}
</style>
