<template>
	<div id="please-wait" class="centered-container">
		<img class="svg partner-logo" v-bind:src="imgLogo" />
		<p id="please-wait-heading" class="standard-heading" v-t="'pleaseWait'" role="heading" aria-level="1" />
	</div>
</template>

<script>
export default {
	name: 'PleaseWait',
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {
		imgLogo: function() {
			return ar.partnerLogoUrl(this.partner);
		}
	},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('pleaseWait');
	}
};
</script>

<style scoped>
</style>
