<template>
    <button
        id="VOICE"
        name="VOICE"
        class="btn"
        :class="{activeRecording: isRecording}"
        data-vcode="VOICE"
        v-bind:disabled="!isVoiceSupported"
        :aria-label="`${$t('voiceButton')}`"
    >
        <img src="/images/icons/voice.svg" alt="" />
        <label aria-hidden="true">{{ $t('voiceButton') }}</label>
    </button>
</template>
<script>
import remoteMixin from '../mixins/remote';

export default {
    mixins: [remoteMixin],
};
</script>
