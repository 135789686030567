<template>
    <!-- Chrome won't surface ARIA live announcements in screen readers if the same content is put into the same live region repeatedly. -->
    <!-- Using two live regions, and swapping between them as the message changes, allows us to get around this if needed. -->
    <!-- One live region will always be empty, and screen readers don't generally read text removals, so using two live regions in this way shouldn't break anything. -->
    <!-- Loosely inspired by how Teams does announcements under the hood. -->
    <div role="group" aria-label="Screen reader announcements"> <!-- We're labeling this and assigning the group role in the off-chance that someone runs into a message before it goes away. -->
        <div class="aria-announcer" aria-live="assertive" aria-atomic="true">{{this.first ? this.msg : ""}}</div>
        <div class="aria-announcer" aria-live="assertive" aria-atomic="true">{{this.first ? "" : this.msg}}</div>
    </div>
</template>

<script>
export default {
    name: 'announcer',
    props: ['message'],
    data() {
        return {
            first: true,
            msg: "",
            timer: null
        }
    },
    methods: {
        speak: function(val) {
            this.first = !this.first;
            this.timer = clearTimeout(this.timer);
            this.msg = val;
            var t = performance.now();
            this.timer = setTimeout(function() {
                this.msg = "";
            }.bind(this),5000);
        }
    },
    watch: {
        message: function(val) {
          this.speak(val);
        }
    }
}
</script>

<style lang="scss" scoped>
.aria-announcer {
    position: absolute !important;
    visibility: visible !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    clip: rect(0px, 0px, 0px, 0px) !important;
    /* deprecated */
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px) !important;
    white-space: nowrap !important;
    /* LFs are not recognized as spaces by screen readers */
}
</style>