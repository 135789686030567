<template>
	<div id="ar-companion-success-page" class="page centered-container">
		<h1 class="standard-heading companion-success">Go back to your Companion App</h1>
	</div>
</template>

<script>

export default {
	name: 'CompanionSuccessPage',
	components: {},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('');
	}
};
</script>

<style scoped>
	.companion-success{
		text-align: center;
	}
</style>
