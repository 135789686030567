<template>
	<div id="ar-companion-home-page" class="page">
		<template v-if="!isCompanionMode">
			<PleaseWait></PleaseWait>
		</template>
		<template v-else>
			<div class="standard-heading companion-home">Companion</div>
			<ChooseAuth></ChooseAuth>
		</template>
	</div>
</template>

<script>
import PleaseWait from '@/components/PleaseWait.vue';
import ChooseAuth from '@/components/ChooseAuth.vue';

export default {
	name: 'CompanionHomePage',
	components: {
		PleaseWait,
		ChooseAuth
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {},
	mounted: function() {
		const companionId = ar.getParameterByName('companionId');

		// If we're returning from a login, we won't have a companionId parameter in the URL; Short circuit in this case.
		if ( ! companionId ) { return; }

		if (ar.isLocalStorageAvailable('localStorage')) {
			ar.saveLocal('companionMode', "true");
			ar.saveLocal('companionId', companionId);
		}

		/* test without these lines below - not working, showing the Please Wait page
		*/
		ar.setState({
			isCompanionMode: true,
			companionId: companionId
		});
		window.focusPageHeading();
		window.setPageTitle('');
	}
};
</script>
<style scoped>
	#ar-companion-home-page{
		margin-top: 5rem;
	}
		#ar-companion-home-page .centered-container{
			position: relative;
			top: 0;
			left: 0;
			transform: none;
			margin: auto;
		}
		.companion-home {
			text-align: center;
		}
</style>
