<template>
	<div>
		<template v-if="menuOpen">
			<div id="blockScreen" class="block-screen" v-on:click="closeMenu"></div>
		</template>
		<div class="header-wrapper">
			<Menu></Menu>
			<RemoteHeader v-bind:heading="$t('remoteHeader')" v-bind:isBeta="false"></RemoteHeader>
		</div>
		<div id="ar-remote-page" class="page mt-global" v-bind:class="{ nonScroll: menuOpen }">
			<Remote></Remote>
		</div>
	</div>
</template>

<script>
import Remote from '@/components/Remote.vue';
import Menu from '@/components/Menu.vue';
import RemoteHeader from '@/components/RemoteHeader.vue';

export default {
	name: 'RemotePage',
	components: {
		Menu,
		RemoteHeader,
		Remote
	},
	props: [],
	data: function () {
		return ar.state;
	},
	methods: {
		closeMenu: function () {
			if (this.menuOpen) {
				this.menuOpen = false;
			}
		}
	},
	computed: {},
	mounted: function () {
		// Use localStorage not the state (ar.isCompanionMode) because the login will cause a page refresh which will set the state to false
		let isCompanionMode = false;
		if (ar.isLocalStorageAvailable('localStorage')) {
			isCompanionMode = ar.getLocalBoolean('companionMode');
		}

		if (isCompanionMode) {
			// Don't actually show remote page
			// Send /api/v1/companion/request ajax call to server
			// Redirect to "thanks for logging in. now go to companion app" page.
			const companionId = ar.getLocal('companionId');
			ar.saveCompanionPairing(companionId);
			ar.logout();
			arRouter.push('/companion-success');
		} else {
			// We're in normal mode, not custom mode
			ar.setState({
				customMode: false
			});
			if (ar.isLocalStorageAvailable('localStorage')) {
				ar.saveLocal('customMode', 'false');
			}
		}
		window.focusPageHeading();
		window.setPageTitle('');
	}
};
</script>

<style lang="scss" scoped>
.page {
	position: absolute;
	height: 100%;
	width: 100%;
}

.header-wrapper {
	display: flex;
	flex-direction: row;
	background-color: black;
    justify-content: flex-end;
}
</style>
