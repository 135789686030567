<template>
	<div class="button-section">
		<div class="button-row">
			<Rewind></Rewind>
			<PlayPause></PlayPause>
			<FastForward></FastForward>
		</div>
		<div class="button-row">
			<Prev></Prev>
			<Home></Home>
			<Info></Info>
		</div>
		<DirPad></DirPad>
	</div>
</template>

<script>
import DirPad from './DirPad.vue';
import FastForward from './FastForward.vue';
import Home from './Home.vue';
import Info from './Info.vue';
import PlayPause from './PlayPause.vue';
import Prev from './Prev.vue';
import Rewind from './Rewind.vue';

export default {
    name: 'DPadFlex',
    components: {
        DirPad,
        FastForward,
        Home,
        Info,
        PlayPause,
        Prev,
        Rewind,
    },
};
</script>
<style lang="scss" scoped>
</style>
