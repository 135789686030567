var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"ar-seaButtons-page"}},[(_vm.globalState.menuOpen)?[_c('div',{staticClass:"block-screen",attrs:{"id":"blockScreen"},on:{"click":_vm.closeMenu}})]:_vm._e(),_c('Menu'),_c('RemoteHeader',{attrs:{"heading":_vm.$t('seaButtonsPageHeadingLabel')}}),_c('div',{staticClass:"seabuttons mt-global",attrs:{"id":"seabuttons","aria-hidden":_vm.ariaHidden}},[_c('div',{attrs:{"id":"seabuttons-buttons"},on:{"click":function($event){$event.preventDefault();return _vm.click.apply(null, arguments)}}},[_c('div',{staticClass:"custom-button-section"},[(_vm.globalState.isFetchingSeaButtons)?[_c('p',{staticClass:"important"},[_vm._v(_vm._s(_vm.$t('seaButtonsPagePleaseWait'))+"...")])]:(_vm.globalState.errFetchingSeaButtons)?[_c('p',{staticClass:"important"},[_vm._v(_vm._s(_vm.$t('seaButtonsPageErrGettingBtnData')))])]:[(
							_vm.globalState.haveSeaButtonsData &&
							_vm.globalState.seaButtonsData &&
							_vm.globalState.seaButtonsData.length > 0
						)?_vm._l((_vm.globalState.seaButtonsData),function(seaButton,j){return _c('button',{key:j,staticClass:"btn",attrs:{"aria-label":seaButton.displayString,"data-command":seaButton.command}},[_c('img',{class:{'soft-img': seaButton.isLegacyIcon},attrs:{"alt":seaButton.command,"src":_vm.safeImage(seaButton.image)}}),_c('label',{attrs:{"aria-hidden":"true"},domProps:{"innerHTML":_vm._s(seaButton.displayString)}})])}):_vm._e(),(
							_vm.globalState.haveSeaButtonsData &&
							_vm.globalState.seaButtonsData &&
							_vm.globalState.seaButtonsData.length === 0
						)?[_c('p',{staticClass:"important"},[_vm._v(" "+_vm._s(_vm.$t('seaButtonsPageNoButtonsYet'))+" ")])]:_vm._e()]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"remote-section"},[(!_vm.globalState.isFlex)?_c('DPadX1'):_vm._e(),(_vm.globalState.isFlex)?_c('DPadFlex'):_vm._e(),_c('FullRemote')],1)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }