var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"edit-button-order-page"}},[_c('div',{attrs:{"id":"seabuttons-config"}},[(_vm.globalState.menuOpen)?[_c('div',{staticClass:"block-screen",attrs:{"id":"blockScreen"},on:{"click":_vm.closeMenu}})]:_vm._e(),_c('Menu'),_c('RemoteHeader',{attrs:{"heading":_vm.headingLabel}}),(_vm.showEditCustomButton)?_c('EditCustomButton',{attrs:{"custom-button-add-mode":_vm.customButtonAddMode,"current-right-command":_vm.currentRightCommand,"right-commands":_vm.rightCommands},on:{"update-custom-button-event":_vm.updateCustomButtonEvent,"update-show-custom-button":_vm.updateShowCustomButton,"update-add-custom-clicked":_vm.updateAddCustomClicked}}):_vm._e(),(_vm.isAddCustomClicked && !_vm.showEditCustomButton)?_c('AddNewButton',{on:{"update-show-custom-button":_vm.updateShowCustomButton,"update-custom-button-add-mode":_vm.updateCustomButtonAddMode}}):_vm._e(),(!_vm.showEditCustomButton && !_vm.isAddCustomClicked)?_c('div',{staticClass:"seabuttons-config",attrs:{"id":"seabuttons-config-main"}},[_c('h3',{staticClass:"heading-secondary"},[_vm._v(" "+_vm._s(_vm.$t('editButtonOrderPageYourFirstButtons'))+" ")]),(!_vm.globalState.haveSeaButtonsData || _vm.globalState.isFetchingSeaButtons)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('editButtonOrderPagePleaseWait'))+"... ")]):_c('div',{staticClass:"custom-buttons-group",attrs:{"id":""}},[(!_vm.rightCommands || _vm.rightCommands.length === 0)?[_c('p',[_vm._v(_vm._s(_vm.$t('editButtonOrderPageNoneSelectedYet'))+".")]),_c('p',[_vm._v(_vm._s(_vm.$t('editButtonOrderPageFtueHelp')))])]:_c('div',{staticClass:"custom-buttons-section",attrs:{"id":"top-buttons-section","role":"listbox"}},[_vm._l((_vm.rightCommands),function(seaButton,i){return [(i === _vm.topButtonsNum && _vm.setButtonIndex(i))?void 0:_vm._e(),(i <= _vm.topButtonsNum)?_c('button',{key:i,ref:`seaButton${i}`,refInFor:true,staticClass:"btn command-button",class:{
								'seabuttons-config-item-active': _vm.isActive(seaButton)
							},attrs:{"role":"option","tabindex":i > 0 ? '-1' : '0',"aria-selected":String(_vm.isActive(seaButton)),"data-command":seaButton.command},on:{"click":function($event){return _vm.handleRightClick($event, seaButton, i)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.handleUpKey.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.handleDownKey.apply(null, arguments)}]}},[_c('img',{class:{ 'soft-img': seaButton.isLegacyIcon },attrs:{"alt":'',"src":_vm.safeImage(seaButton.image)}}),_c('label',{staticClass:"button-description-cell button-description-value",domProps:{"innerHTML":_vm._s(seaButton.displayString)}})]):_vm._e()]})],2)],2),_c('div',{staticClass:"seabuttons-config-lower-action"},[(_vm.buttonIndex === _vm.topButtonsNum)?[_c('div',{staticClass:"custom-buttons-gradient",class:{ 'one-page-buttons': _vm.disableScroll }},[_c('div',{staticClass:"custom-buttons-scroll",attrs:{"role":"listbox"}},[_vm._l((_vm.rightCommands),function(seaButton,i){return [(i > _vm.topButtonsNum)?_c('button',{key:i,ref:`seaButton${i}`,refInFor:true,staticClass:"btn command-button",class:{
										'seabuttons-config-item-active': _vm.isActive(seaButton)
									},attrs:{"role":"option","tabindex":i > _vm.topButtonsNum + 1 ? '-1' : '0',"aria-selected":String(_vm.isActive(seaButton)),"data-command":seaButton.command},on:{"click":function($event){return _vm.handleRightClick($event, seaButton, i)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.handleUpKey.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.handleDownKey.apply(null, arguments)}]}},[_c('img',{class:{ 'soft-img': seaButton.isLegacyIcon },attrs:{"alt":'',"src":_vm.safeImage(seaButton.image)}}),_c('label',{staticClass:"button-description-cell button-description-value",domProps:{"innerHTML":_vm._s(seaButton.displayString)}})]):_vm._e()]})],2)])]:_vm._e(),(_vm.rightCommands.length > 0)?_c('div',{staticClass:"seabuttons-config-order-dpad"},[_c('button',{attrs:{"aria-label":"Scroll Up","disabled":_vm.disableScroll},on:{"click":_vm.handleScrollUp}},[_c('img',{attrs:{"src":"/images/icons/pageUp.svg","alt":"","role":"img","aria-hidden":"true"}}),_c('label',[_vm._v("Scroll up")])]),_c('button',_vm._g({attrs:{"disabled":_vm.isNavDisabledFirst,"aria-disabled":_vm.isNavDisabledFirst,"aria-label":"Up"}},
							_vm.isOKToggled
								? { click: (e) => _vm.handleMove(e, _vm.getDirection(_vm.directionUp)) }
								: { click: (e) => _vm.handleFocus(e, _vm.getDirection(_vm.directionUp)) }
						),[_c('img',{staticClass:"up-arrow",attrs:{"src":"/images/icons/upArrow.svg","alt":"","role":"img","aria-hidden":"true"}})]),(_vm.isDeleteVisible)?_c('button',{attrs:{"aria-label":"Delete command","disabled":_vm.isDeleteDisabled,"aria-disabled":_vm.isDeleteDisabled},on:{"click":_vm.handleDeleteClick}},[_c('img',{staticClass:"img-mt-1",attrs:{"src":"/images/icons/icon_trash_outline.svg","alt":"","role":"img","aria-hidden":"true"}}),_c('label',[_vm._v(_vm._s(_vm.$t('editButtonOrderPageDeleteItem')))])]):_vm._e(),_c('button',_vm._g({attrs:{"disabled":_vm.isNavDisabledFirst,"aria-disabled":_vm.isNavDisabledFirst,"aria-label":"Left"}},
							_vm.isOKToggled
								? { click: (e) => _vm.handleMove(e, _vm.getDirection(_vm.directionLeft)) }
								: { click: (e) => _vm.handleFocus(e, _vm.getDirection(_vm.directionLeft)) }
						),[_c('img',{staticClass:"left-arrow",attrs:{"src":"/images/icons/leftArrow.svg","alt":"","role":"img","aria-hidden":"true"}})]),_c('button',{class:{ toggled: _vm.isOKToggled },attrs:{"aria-label":"OK toggle"},on:{"click":_vm.handleOKToggle}},[_c('span',{staticClass:"ok-button"},[_vm._v("OK")])]),_c('button',_vm._g({attrs:{"disabled":_vm.isNavDisabledLast,"aria-disabled":_vm.isNavDisabledLast,"aria-label":"Right"}},
							_vm.isOKToggled
								? { click: (e) => _vm.handleMove(e, _vm.getDirection(_vm.directionRight)) }
								: { click: (e) => _vm.handleFocus(e, _vm.getDirection(_vm.directionRight)) }
						),[_c('img',{staticClass:"right-arrow",attrs:{"src":"/images/icons/rightArrow.svg","alt":"","role":"img","aria-hidden":"true"}})]),_c('button',{attrs:{"aria-label":"Scroll Down","disabled":_vm.disableScroll},on:{"click":_vm.handleScrollDown}},[_c('img',{attrs:{"src":"/images/icons/pageDown.svg","alt":"","role":"img","aria-hidden":"true"}}),_c('label',[_vm._v("Scroll down")])]),_c('button',_vm._g({attrs:{"disabled":_vm.isNavDisabledLast,"aria-disabled":_vm.isNavDisabledLast,"aria-label":"Down"}},
							_vm.isOKToggled
								? { click: (e) => _vm.handleMove(e, _vm.getDirection(_vm.directionDown)) }
								: { click: (e) => _vm.handleFocus(e, _vm.getDirection(_vm.directionDown)) }
						),[_c('img',{attrs:{"src":"/images/icons/downArrow.svg","alt":"","role":"img","aria-hidden":"true"}})]),_c('button',{on:{"click":_vm.handleAddClick}},[_c('img',{staticClass:"add-button",attrs:{"src":"/images/icons/icon_addcircle_outline.svg","alt":"","role":"img","aria-hidden":"true"}}),_c('label',[_vm._v(_vm._s(_vm.$t('editButtonOrderPageAddItem')))])])]):_vm._e()],2),_c('div',{staticClass:"finish-actions"},[_c('button',{staticClass:"standard-button primary",on:{"click":_vm.handleSaveButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('editButtonOrderPageSave'))+" ")]),_c('button',{staticClass:"standard-button secondary",on:{"click":_vm.handleCancelButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('editButtonOrderPageCancel'))+" ")]),(_vm.isEditVisible)?_c('button',{staticClass:"text-command-button standard-button ghost",attrs:{"aria-label":"Edit custom button","disabled":_vm.isEditDisabled,"aria-disabled":_vm.isEditDisabled},on:{"click":_vm.handleCustomClick}},[_vm._v(" "+_vm._s(_vm.$t('editButtonOrderPageEditItem'))+" ")]):_vm._e()]),(_vm.isUpDownVisible)?_c('div',{staticClass:"up-down-action-buttons"}):_vm._e()]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }