<template>
	<div id="ar-choose-device-page" class="page">
		<ChooseDevice></ChooseDevice>
	</div>
</template>

<script>
import i18nJ from '@/plugins/i18nJ';
import ChooseDevice from '@/components/ChooseDevice.vue';

export default {
	name: 'ChooseDevicePage',
	components: {
		ChooseDevice
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {},
	mounted: function() {
		const arToken = ar.state.arToken;
		if ( ! arToken ) {
			const msg = i18nJ.t('cantSeeStbsWithoutLogin');
			arRouter.push('/error?msg=' + msg);
			return;
		}
		window.focusPageHeading();
		// We're setting the page title in the ChooseDevice component, rather than here.
	}
};
</script>

<style scoped>
</style>
