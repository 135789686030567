<template>
	<button v-show="showMe" class="btn" @click="handleViewAll">
		<img class="soft-img" src="/images/icons/icon_gridview_outline.svg" alt="" role="img" aria-hidden="true" />
		<label>{{ $t('editButtonOrderPageViewAll') }}</label>
	</button>
</template>
<script>
import editButtonsMixin from '../mixins/editButtons';

export default {
	props: ['showMe'],
	mixins: [editButtonsMixin]
};
</script>
