// Analytics-Related Functionality

// Master switch; Set to false to immediately stop analytics
let enabled        = window.arff?.analyticsEnabled || false;

// If false, analytics collection occurs but without user ids (clientDeviceId values)
let shouldIdentify = window.arff?.analyticsIdentify || false;

// If true, get helpful debugging messages (dev only)
let shouldLog      = window.arff?.analyticsLog || false;

/**
 * Control whether logging is on or off
 * @param  {Boolean} shouldLog                   Set to true for logs; false for no logs
 * @return {undefined}
 */
function debug(pShouldLog) {
	shouldLog = pShouldLog;
}

/**
 * Link users and their actions to a recognizable userId and traits
 * @param  {Object}  params                      Parameters object
 * @param  {String}  params.userId               The current clientDeviceId value; required
 * @param  {Array}   params.traits               A dictionary of traits known about the user; optional
 * @param  {Integer} params.callback             A function executed after a timeout of 300 ms, giving the browser time to make outbound requests first; optional
 * @return {undefined}
 */
function identify(params) {
	if ( ! enabled ) { return; }
	if ( ! shouldIdentify ) { return; }

	let { userId, traits, callback } = params;

	if ( ! event ) {
		console.log('analytics: identify: Missing userId parameter')
		return;
	}

	if ( shouldLog ) {
		const paramsToLog = {};
		['userId', 'traits'].forEach((key) => {
			paramsToLog[key] = params[key];
		});
		console.log(`analytics: identify: ${JSON.stringify(paramsToLog)}`);
	}

	// Google implementation
	gtag('config', 'G-2GV096QXDY', {
		'user_id': userId
	});
}

/**
 * Associate an identified user with some kind of group (e.g., syndication partner)
 * @param  {Object}  params                      Parameters object
 * @param  {String}  params.groupId              The Group ID to associate with the current user; required
 * @param  {Integer} params.traits               A dictionary of traits for the group; optional
 * @param  {Integer} params.callback             A function executed after a timeout of 300 ms, giving the browser time to make outbound requests first; optional
 * @return {undefined}
 */
function group(params) {
	if ( ! enabled ) { return; }

	let { groupId, traits, callback } = params;

	if ( ! event ) {
		console.log('analytics: group: Missing groupId parameter')
		return;
	}

	if ( shouldLog ) {
		const paramsToLog = {};
		['groupId', 'traits'].forEach((key) => {
			paramsToLog[key] = params[key];
		});
		console.log(`analytics: group: ${JSON.stringify(paramsToLog)}`);
	}

	// Google implementation
	gtag('set', 'partner', {
		id: params?.groupId,
		...traits
	});
}

/**
 * Record page views within app, along with extra information about the page viewed by the user. Note: You must call this method at least once per page load. You can call it more than once if needed, for example, on virtual page changes in a single page app
 * @param  {Object}  params                      Parameters object
 * @param  {String}  params.pageName             The name of the page; required
 * @param  {Integer} params.properties           A dictionary of properties of the page; optional; Note: url, title, referrer, path are automatically included
 * @param  {Integer} params.callback             A function executed after a timeout of 300 ms, giving the browser time to make outbound requests first; optional
 * @return {undefined}
 */
function page(params) {
	if ( ! enabled ) { return; }

	let { pageName, properties, callback } = params;

	if ( ! pageName ) {
		console.log('analytics: page: Missing pageName parameter')
		return;
	}

	if ( shouldLog ) {
		const paramsToLog = {};
		['pageName', 'properties'].forEach((key) => {
			paramsToLog[key] = params[key];
		});
		console.log(`analytics: page: ${JSON.stringify(paramsToLog)}`);
	}

	// Google implementation
	gtag('event', 'page_view', {
		page_name: pageName,
		...properties
	});
}

/**
 * Record actions users perform
 * @param  {Object}  params                      Parameters object
 * @param  {String}  params.eventName            Concise description of the event that took place; r.g., 'stb_button'; required
 * @param  {String}  params.properties           A dictionary of properties for the event; optional
 * @param  {Integer} params.callback             A function executed after a timeout of 300 ms, giving the browser time to make outbound requests first; optional
 * @return {undefined}
 */
function track(params) {
	if ( ! enabled ) { return; }

	let { eventName, properties, callback } = params;

	if ( ! eventName ) {
		console.log('analytics: track: Missing eventName parameter')
		return;
	}

	if ( shouldLog ) {
		const paramsToLog = {};
		['eventName', 'properties'].forEach((key) => {
			paramsToLog[key] = params[key];
		});
		console.log(`analytics: track: ${JSON.stringify(paramsToLog)}`);
	}

	// Google implementation
	gtag('event', eventName, properties);
}

// Exported API
const Analytics = {
	debug    : debug,
	identify : identify,
	group    : group,
	page     : page,
	track    : track
};

export { Analytics };
