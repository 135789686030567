// "Plain ol' JavaScript" utilities related to Internationalization

import getPartnerMessages from '@/partnerMessages';
const partner = ar.getPartnerFromUrl();
const corePartner = ar.getCorePartnerFromPartner(partner);
const messages = getPartnerMessages(corePartner);

// msgCode is a valid key (repeated within each language's section) within the messages object
// locale is 'en', 'es', or 'fr'
function t(msgCode) {
	// If true, bad localess or missing message codes just 'disappear' in the UI
	// If false, these issues are seen in broad daylight and should be quikly fixed
	const quiet = false;

	let locale; 
	if ( ar.isLocalStorageAvailable('localStorage') ) {
		locale = ar.getLocal('locale');
	}
	if ( ! locale ) { locale = ar.getDefaultLocaleFromUrl(); }

	// get language part of locale in case locale is stored like 'de_DE'
	const language = locale.slice(0, 2);
	if ( ! messages.hasOwnProperty(language) ) {
		// if 'language' isn't in messages.js set default to 'en'
		language = 'en'
	}

	// if message doesn't exist try 'en' message
	const msg = messages[language][msgCode];
	if ( ! msg ) {
		msg = messages['en'][msgCode];
		if ( ! msg ) {
			return ( quiet ? '' : '*** INTERNAL I18N ERROR: BAD MESSAGE CODE ***' );
		}
	}

	return msg;
}

const i18nJ = {
	t: t
};
// Stash for use within "regular" JavaScript (outside of VueJS)
// Specifically, within AccessibleRemote.js
ar.i18nJ = i18nJ;

export default i18nJ;
