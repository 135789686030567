<template>
    <button class="btn" @click="handleFullRemoteClick">
        <img class="soft-img" src="/images/icons/fullRemote.svg" alt="" role="img" aria-hidden="true" />
        <label>{{ $t('editButtonOrderPageFullRemote') }}</label>
    </button>
</template>
<script>
import editButtonsMixin from '../mixins/editButtons'

export default {
    mixins: [editButtonsMixin],
};
</script>
