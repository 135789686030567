export default {
	data: function () {
		return ar.state;
	},

	computed: {
		imgLogo: function () {
			return ar.partnerLogoUrl(this.partner);
		},
	}
};
