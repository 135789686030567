<template>
	<div class="dpad-group">
		<div class="button-row single">
			<button id="UP" name="UP" class="btn" :aria-label="$t('upArrow')" data-vcode="UP">
				<img src="/images/icons/upArrow.svg" alt="" role="img" aria-hidden="true" />
			</button>
		</div>
		<div class="button-row">
			<button
				id="LEFT"
				name="LEFT"
				class="btn"
				:aria-label="$t('leftArrow')"
				data-vcode="LEFT"
			>
				<img src="/images/icons/leftArrow.svg" alt="" role="img" aria-hidden="true" />
			</button>
			<button id="OK" name="OK" class="btn txt-btn uppercase" data-vcode="ENTER">
				{{ $t('okButton') }}
			</button>
			<button
				id="RIGHT"
				name="RIGHT"
				class="btn"
				:aria-label="$t('rightArrow')"
				data-vcode="RIGHT"
			>
				<img src="/images/icons/rightArrow.svg" alt="" role="img" aria-hidden="true" />
			</button>
		</div>
		<div class="button-row" :class="{ single: !onSeaButtonsPage }">
			<EditPopularButton></EditPopularButton>
			<button
				id="DOWN"
				name="DOWN"
				class="btn"
				:aria-label="$t('downArrow')"
				data-vcode="DOWN"
			>
				<img src="/images/icons/downArrow.svg" alt="" role="img" aria-hidden="true" />
			</button>
			<AddPopularButton :showMe="onSeaButtonsPage"></AddPopularButton>
		</div>
	</div>
</template>
<script>
import editButtonsMixin from '@/components/mixins/editButtons';
import AddPopularButton from './AddPopularButton.vue';
import EditPopularButton from './EditPopularButton.vue';

export default {
	mixins: [editButtonsMixin],
	components: { AddPopularButton, EditPopularButton },
};
</script>
<style lang="scss" scoped>
.remote-section {
	.button-section {
		.dpad-group {
			margin-bottom: 0;
		}
	}
}
.dpad-group {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 3rem;

	.single {
		max-width: 6.25rem;
	}
}
</style>
