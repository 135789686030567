<template>
	<div id="add-buttons-page" class="page">
		<div id="add-buttons-container" class="">
			<template v-if="globalState.menuOpen">
				<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
			</template>
			<Menu></Menu>
			<RemoteHeader :heading="$t('customButtonAddPageHeader')"></RemoteHeader>
			<AddNewButton />
		</div>
	</div>
</template>

<script>
import RemoteHeader from "@/components/RemoteHeader.vue";
import editButtonsMixin from "@/components/mixins/editButtons";
import Menu from '@/components/Menu.vue';
import AddNewButton from "@/components/AddNewButton.vue";

export default {
	name: "AddNewButtonPage",
	mixins: [editButtonsMixin],
	components: {
		RemoteHeader,
		Menu,
		AddNewButton
	},	
	methods: {
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		}
	},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('customButtonAddPageHeader'); // this isn't always working because page doesn't get mounted consistently when opening from Edit Buttons page
	}
};
</script>

<style lang="scss" scoped>
#add-buttons-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}
</style>
