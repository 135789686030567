<template>
	<div id="accessible-remote-footer"></div>
</template>

<script>
export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {}
	},
	methods: {},
	computed: {}
};
</script>

<style scoped>
</style>
