<template>
    <button id="HOME" name="HOME" class="btn" :data-command="$t('homeButton')">
        <img src="/images/icons/home.svg" alt="" role="img" aria-hidden="true" />
        <label>{{ $t('homeButton') }}</label>
    </button>
</template>
<script>
import remote from '../mixins/remote';

export default {
    mixins: [remote],
};
</script>
