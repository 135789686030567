<template>
	<div id="accessible-remote-container">
		<Header></Header>
		<Msg v-bind:msg="msg"></Msg>
		<Content
			v-bind:pairingCode="pairingCode"
			v-bind:errorMessage="errorMessage"
			v-bind:shortcutLink="shortcutLink"
		></Content>
		<div id="sounds" style="display:none;">
			<audio id="sound-up" src="/sounds/Xfinity_Up_Mobile_ST-20LKFS.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
			<audio id="sound-down" src="/sounds/Xfinity_Down_Mobile_ST-20LKFS.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
			<audio id="sound-left-or-right" src="/sounds/Xfinity_Side_Mobile_ST-20LKFS.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
			<audio id="sound-select" src="/sounds/Xfinity_Select_Tap_Mobile_ST-20LKFS.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
			<audio id="sound-confirm" src="/sounds/Xfinity_Confirm_Mobile_ST-20LKFS.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
			<audio id="sound-listening" src="/sounds/listening.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
			<audio id="sound-listening-complete" src="/sounds/listening_complete.wav">
				Your browser does not support the <code>audio</code> element.
			</audio>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import ar from '@/AccessibleRemote';
import i18nJ from '@/plugins/i18nJ';
import i18n from '@/plugins/i18n';
import { Analytics } from '../../src/analytics.js';
import Header from '@/components/Header.vue'
import Msg from '@/components/Msg.vue'
import Content from '@/components/Content.vue'
import Footer from '@/components/Footer.vue'

export default {
	name: 'AccessibleRemote',
	components: {
		Header, Msg, Content, Footer
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {
		changeLocale: function(locale) {
			i18n.locale = locale;				// Needed for <p v-t="'<msgCode>'"></p> to work
			ar.saveLocal('locale', locale);
		},
	},
	computed: {
		shortcutLink: {
			get: function() {
				var url;
				/**
				 * @todo: not sure what this is doing, needs clean up?
				 */
				if ( this.clientDeviceId && this.arToken ) {
					url = window.location.href
					    + '?cd=' + this.clientDeviceId
					    + '&ept=' + this.arToken
					return url;
				} else {
					return null;
				}
			}
		}
	},
	mounted: function() {
		var msg, qsClientDeviceId, qsARToken;

		if ( arRouter.currentRoute.name === 'fourOhFourPage' ) {
			return;
		}

		if ( ! ar.isLocalStorageAvailable('localStorage') ) {
			const msg = i18nJ.t('appRequiresLocalStorage');
			arRouter.push('/error?msg=' + msg);
			return;
		}

		// Handle partner in/on URL / set based on local storage if appropriate
		let partner = ar.getPartner();
		this.partner = partner;
		if ( partner ) {
			Analytics.group({ groupId: partner });
		}

		// Set title
		// document.title = ar.i18nJ.t('remoteHeader');
		window.setPageTitle('chooseHowToSetUp');

		// Handle msg on URL
		msg = ar.getParameterByName('msg');
		msg = ar.translateMessage(msg);
		if ( window.location.pathname === '/error' ) {
			ar.clearMessage(); // Will be shown within main area of page
		} else {
			if ( msg ) {
				ar.showMessage(msg);
			} else {
				ar.clearMessage();
			}
		}

		// Allow query string parameters for users who clear browsing history upon browser exit
		// which for some reason clears localStorage
		qsClientDeviceId = ar.getParameterByName('cd');
		qsARToken = ar.getParameterByName('ept') || ar.getParameterByName('arToken');

		ar.init(qsClientDeviceId, qsARToken);

		const self = this;
		ar.getVersion(function (locale){
			self.changeLocale(locale);
		});
	}
};
</script>

<style scoped>
</style>
