<template>
	<div id="ar-home-page" class="page">
		<ChooseAuth></ChooseAuth>
	</div>
</template>

<script>
import ChooseAuth from '@/components/ChooseAuth.vue';

export default {
	name: 'HomePage',
	components: {
		ChooseAuth
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {},
	mounted: function() {
		// NOTE: This was added because at some point there was an issue in the UI
		// that during login the component was not loaded fast enough and partner state
		// was undefined. That issue seems to be fixed now (not sure how) — leaving
		// this code commented out in case it's needed later
		// const url = window.location.href.replace('#', '?');
		// const partner = ar.getPartner(url);
		// ar.setState({ partner });
		if ( ar.isLocalStorageAvailable('localStorage') ) {
			ar.saveLocal('companionMode', 'false');
		}
		ar.setState({
			isCompanionMode: false
		});
		window.focusPageHeading();
		window.setPageTitle('chooseHowToSetUp');
	}
};
</script>


<style scoped>
</style>