<template>
    <button
        id="VOLUME_DOWN"
        name="VOLUME_DOWN"
        class="btn"
        data-vcode="VolumeDown"
        disabled
        :aria-label="`${$t('volumeDown')}`"
    >
        <img src="/images/icons/volumeDown.svg" alt="" />
        <label aria-hidden="true">{{ $t('volumeDown') }}</label>
    </button>
</template>
<script>
export default {};
</script>
