// Given messages.js file, which can contain partner-specific sub-objects as values for message keys,
// and the current corePartner value, return a messages object with these sub-objects removed and
// replaced by the appropriate message (for the specific partner or the default)

import messages from './messages.js';

const partnerMessages = JSON.parse(JSON.stringify(messages));

function getMessagesForPartner(corePartner) {
	for (let langKey in partnerMessages) {
 		if (partnerMessages.hasOwnProperty(langKey)) {
 			for ( let msgKey in partnerMessages[langKey] ) {
 				if ( typeof partnerMessages[langKey][msgKey] === 'object' ) {
 					if ( partnerMessages[langKey][msgKey].hasOwnProperty(corePartner) ) {
 						partnerMessages[langKey][msgKey] = partnerMessages[langKey][msgKey][corePartner]
 					} else {
 						partnerMessages[langKey][msgKey] = partnerMessages[langKey][msgKey]['default'];
 					}
 				}
 			}
 		}
	}

	return partnerMessages;
}

export default getMessagesForPartner;
