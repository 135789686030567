<template>
	<div id="learn-more-page" class="page">
		<template v-if="globalState.menuOpen">
			<div id="blockScreen" class="block-screen" v-on:click="closeMenu"></div>
		</template>
		<div id="learn-more-body" class="">
			<div class="header-wrapper">
				<Menu></Menu>
				<RemoteHeader :heading="$t('learnMore')"></RemoteHeader>
			</div>
			<div v-show="showLeftFeature" class="feature-container">
				<h2 tabindex="-1" id="ifttt-heading">IFTTT</h2>
				<p>
					This Document explains how you can control your TV with the Web Remote app with
					the help of one or more IoT ("Internet of Things") devices, and some initial
					setup and configuration. You can find a step-by-step guide to making it all work here:
					<a class="learn-link" href="#" @click.prevent="handleIFTTTPageNavigation">TV Control via Adaptive Remote and IoT Devices</a>.
				</p>
				<h2>Background</h2>
				<p>
					The Web Remote app lets you control your Xfinity set-top box using a web browser
					or assistive technologies on a PC. Because of limitiations in the Web Remote,
					buttons such as Power On/Off, Volume Up/Down and changing TV inputs are usually
					disabled. IFTTT allows you to enable those buttons using an external USB IR
					device
				</p>
				<h2>Quick Overview</h2>
				<p>
					When things are all set up, here's how IFTTT will work: when you "click" a TV
					Control button in the Web Remote app, it will send a request over the internet
					to <a class="learn-link" href="https://ifttt.com" target="_blank">ifttt.com</a> and using an "applet" which you set
					up on ifttt.com, the command is received by your external IR device which sends
					the command to your TV, toggle a smart switch, or indeed any command you set up
					on ifttt.com. The end result is the ability for you to control your TV from the
					Web Remote app.
				</p>
				<button @click="handleBack" class="learn-button">Back</button>
			</div>
			<div v-show="showRightFeature" class="feature-container">
				<h2 tabindex="-1" id="custom-buttons-heading">Custom Buttons</h2>
				<p>
					Make it easier to access the content you love by setting up custom buttons. For
					example, create a button that will play all the streams by your favorite actor,
					or set up a button to show your favorite movies.
				</p>
				<img src="/images/learn-more-custom.png" alt="Screenshot showing the 'Add button' button, followed by 3 example custom buttons with images added to them."/>
				<button @click="handleBack" class="learn-button">Back</button>
			</div>
			<div v-show="!showLeftFeature && !showRightFeature" class="learn-container">
				<div class="learn-main">
					<h2 class="feature-h1" id="new-features-heading" tabindex="-1">New features available</h2>
					<p>
						We've added two new features that can improve the way you enjoy our
						services. Read more below about IFTTT (if this, then that) integration, as
						well as our development of custom buttons.
					</p>
				</div>
				<div class="learn-features">
					<div class="feature">
						<h3 class="feature-h1">IFTTT</h3>
						<p>
							IFTTT allows you to streamline your processes, cutting down on the time
							and effort it takes to do what you need to do. You can set up actions to
							do just about anything, from automating system controls to defaulting to
							your favorite channel.
						</p>
						<a class="learn-link" @click.prevent="handleClickReadLeft" href="#" aria-label="Read more about IFTTT">Read more</a>
					</div>
					<div class="feature">
						<h3 class="feature-h1">Custom buttons</h3>
						<p>Make it easier to access the content you love by setting up custom buttons. For
					example, create a button that will play all the streams by your favorite actor,
					or set up a button to show your favorite movies.</p>
						<a class="learn-link" @click.prevent="handleClickReadRight" href="#" aria-label="Read more about custom buttons">Read more</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RemoteHeader from '@/components/RemoteHeader.vue';
import Menu from '@/components/Menu.vue';

export default {
	name: 'LearnMorePage',
	components: {
		RemoteHeader,
		Menu
	},
	data: function () {
		return {
			globalState: ar.state,
			showLeftFeature: false,
			showRightFeature: false
		};
	},
	methods: {
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		},
		handleClickReadRight: function () {
			this.showRightFeature = true;
		},
		handleClickReadLeft: function () {
			this.showLeftFeature = true;
		},
		handleBack: function () {
			this.showLeftFeature = false;
			this.showRightFeature = false;
		},
		handleIFTTTPageNavigation: function() {
			arRouter.push('/ifttt-howto');
		}
	},
	watch: {
		showLeftFeature: function(val) {
			this.$nextTick(function() {
				let tgt = document.getElementById(val === true ? "ifttt-heading" : "new-features-heading")
				tgt.focus();
			});
		},
		showRightFeature: function(val) {
			this.$nextTick(function() {
				let tgt = document.getElementById(val === true ? "custom-buttons-heading" : "new-features-heading");
				tgt.focus();
			});
		}
	},
	mounted: function () {
		console.log('ARSTATE: ', { ...ar.state });
		window.focusPageHeading();
		window.setPageTitle('learnMore');
	}
};
</script>

<style lang="scss" scoped>
/* ---------- */
#learn-more-page {
	background-color: $ar-background-color;
	height: 100vh;
}
.feature-h1 {
	font-size: 2rem !important;
/* 	margin-block-start: 0.67rem;
	margin-block-end: 0.67rem; */
}
.header-wrapper {
	width: 100vw;
}
#learn-more-body {
	display: grid;
	align-content: flex-start;
}
.learn-container,
.feature-container {
	display: grid;
	justify-self: center;
	max-width: 44.25rem;
	margin: 1rem;
	margin-bottom: 5rem;

	@include md {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}

	@include lg {
		margin-left: 5rem;
		margin-right: 5rem;
	}

	img {
		max-width: 33rem;
		margin-top: 2rem;
	}

	p {
		font-family: 'XfinityBrown-Regular';
		font-size: 1.5rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	button {
		background-color: transparent;
		border: none;
		color: white;
		font-size: 1.25rem;
		padding: 0;
		cursor: pointer;
		padding-top: 1rem;
		padding-bottom: 0.25rem;
		justify-self: end;
		border-bottom: 2px solid white;
	}
}
.learn-main {
	margin-bottom: 4rem;
}

.learn-link {
	font-size: 1.5rem;
	display: inline-block;
	text-decoration: underline;
	cursor: pointer;
	@include focus-pseudo-class;
}

.learn-button {
	@include focus-pseudo-class;
}

.learn-features {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.feature {
		width: 18rem;

		p {
			margin-bottom: 2rem;
		}
	}
}

#custom-buttons-config {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $ar-background-color;

	@include lg {
		align-items: flex-start;
	}
}
</style>
