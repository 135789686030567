import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import wb from "./registerServiceWorker";
import FlagIcon from 'vue-flag-icon';
import $ from 'jquery';

window.$ = window.jQuery = $;
window.pageAlreadyLoaded = false; // Set this to true when App.vue mounts. This way, we can run focusPageHeading when any individual component mounts WITHOUT also doing so when someone loads the site up for the first time, or manually refreshes.

window.getPageHeading = (selector) => {
	return document.querySelector(selector ? selector : "h1, [role='heading'][aria-level='1']");
}

window.focusPageHeading = (selector) => {
	let heading = getPageHeading(selector);
	if(heading && window.pageAlreadyLoaded) {
		heading.setAttribute("tabindex","-1");
		heading.focus();
	}
}

window.setPageTitle = (translatableString) => {
	let baseTitle = `${ar.i18nJ.t('remoteHeader')}`;
	try {
		document.title = translatableString ? `${ar.i18nJ.t(translatableString)} - ${baseTitle}` : baseTitle;
	} catch {

	}
}

window.addEventListener("focusin", (e) => {
	if(e.relatedTarget) {
		if(ar.state.menuOpen && e.relatedTarget.closest("#menu") && !e.target.closest("#menu")) {
			document.getElementById("menu_btn").focus(); // Hack fix for Safari that prevents tabbing outside the menu if if "Press Tab to highlight each item on a webpage" is turned off.
		}
	}
});


Vue.prototype.$workbox = wb;
Vue.use(FlagIcon);
Vue.config.productionTip = false;

// "Global" VueJSS viewmodel object
// Keep access to it for hackery
ar.vm = new Vue({
	router,
	i18n,
	render(h) { return h(App); },
}).$mount('#arApp');