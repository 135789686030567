<template>
  <button id="SHORTCUT" name="SHORTCUT" class="btn" aria-label="Shortcut" data-vcode="SHORTCUT" disabled>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg" aria-hidden="true"
      focusable="false">
      <path
        d="M21 13h-6.6l4.7 4.7l-1.4 1.4l-4.7-4.7V21h-2v-6.7L6.3 19l-1.4-1.4L9.4 13H3v-2h6.6L4.9 6.3l1.4-1.4L11 9.6V3h2v6.4l4.6-4.6L19 6.3L14.3 11H21v2Z">
      </path>
    </svg>
  </button>
</template>
<script>
export default {};
</script>
