<template>
	<div class="button-section">
		<div class="button-row">
			<Prev></Prev>
			<Menu></Menu>
			<PageUp></PageUp>
		</div>
		<div class="button-row">
			<Info v-if="currentRoute !== 'seaButtonsPage'"></Info>
			<Exit v-if="currentRoute === 'seaButtonsPage'"></Exit>
			<Guide></Guide>
			<PageDown></PageDown>
		</div>
		<DirPad></DirPad>
	</div>
</template>

<script>
import DirPad from './DirPad.vue';
import Exit from './Exit.vue';
import Guide from './Guide.vue';
import Info from './Info.vue';
import Menu from './Menu.vue';
import PageDown from './PageDown.vue';
import PageUp from './PageUp.vue';
import Prev from './Prev.vue';

export default {
	name: 'DPadX1',
	components: { 
		DirPad,
		Exit,
		Guide,
		Info,
		Menu,
		PageDown,
		PageUp,
		Prev
	},
	props: [],
	computed: {
		currentRoute: function () {
			return this.$route.name;
		}
	}
};
</script>
