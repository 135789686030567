<template>
	<div id="ar-tvconfig-page" @focus.stop="disableGroupFocus" tabindex="0">
		<template v-if="globalState.menuOpen">
			<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
		</template>
		<Menu></Menu>
		<RemoteHeader v-bind:heading="headingLabel"></RemoteHeader>
		<div id="tvbuttons-container" class="mt-global" v-show="!isLoading">
			<TVConfigAPIKey
				v-if="showTvConfigApiKey"
				@input="updateApiKey"
				@update-has-focus-api="updateHasFocusApi"
				:has-focus-api="hasFocusApi"
				:api-key="apiKey"
				v-model="apiKey"
			/>
			<TVConfigEventName
				v-if="!showTvConfigApiKey"
				:api-key="apiKey"
				@update-buttons-events="updateButtonsEvents"
				@update-has-focus="updateHasFocus"
				:tv-buttons-events="tvButtonsEvents"
				:has-focus="hasFocus"
				v-model="tvButtonsEvents"
			/>
			<div id="main-actions">
				<button
					class="standard-button primary"
					v-show="!(showTvConfigApiKey || isNewTVButtonsUser || apiKeyUpdated || eventNamesUpdated)"
					@click="handleCancelButtonClick"
				>
					{{ $t('tvConfigPageConfigReturn') }}
				</button>
				<button
					class="standard-button primary"
					:disabled="!apiKeyUpdated && !eventNamesUpdated"
					v-show="showTvConfigApiKey || isNewTVButtonsUser || apiKeyUpdated || eventNamesUpdated"
					@click="handleSaveButtonClick"
				>
					{{ $t('tvConfigPageConfigSave') }}
				</button>
				<button class="standard-button tvconf-buttons-cancel secondary" @click="handleCancelButtonClick">
					{{ $t('tvConfigPageConfigCancel') }}
				</button>
				<button
					v-show="!showTvConfigApiKey"
					class="eventname-button standard-button ghost"
					@click="toggleShowTvConfigApiKey"
				>
					{{ $t('tvConfigPageConfigEditKey') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import RemoteHeader from '@/components/RemoteHeader.vue';
import Menu from '@/components/Menu.vue';
import TVConfigAPIKey from '@/components/TVConfigAPIKey.vue';
import TVConfigEventName from '@/components/TVConfigEventName.vue';
import tvConfigMixins from '@/components/mixins/tvConfig';

export default {
	name: 'TVConfigPage',
	components: {
		RemoteHeader,
		Menu,
		TVConfigAPIKey,
		TVConfigEventName,
	},
	mixins: [tvConfigMixins],
	data: function () {
		return {
			apiKey: '',
			apiKeyOrig: '',
			globalState: ar.state,
			tvConfigData: {},
			originalTvConfigJson: '{}',
			showTvConfigApiKey: false,
			isNewTVButtonsUser: true,
			isLoading: true,
			hasFocusApi: false,
			hasFocus: {
				mute: false,
				power: false,
				tvInput: false,
				tvPower: false,
				volumeDown: false,
				volumeUp: false,
			},
			tvButtonsEventsOrig: {
				mute: '',
				power: '',
				tvInput: '',
				tvPower: '',
				volumeDown: '',
				volumeUp: '',
			},
			tvButtonsEvents: {
				mute: '',
				power: '',
				tvInput: '',
				tvPower: '',
				volumeDown: '',
				volumeUp: '',
			},
		};
	},
	computed: {
		apiKeyUpdated: function () {
			return this.apiKey !== this.apiKeyOrig;
		},
		eventNamesUpdated: function () {
			return JSON.stringify(this.tvButtonsEvents) !== JSON.stringify(this.tvButtonsEventsOrig);
		},
		headingLabel: function () {
			const editing =
				Object.keys(this.hasFocus).find((key) => this.hasFocus[key] === true) ||
				this.hasFocusApi ||
				(!this.isLoading && this.isNewTVButtonsUser);
			return `${ar.i18nJ.t(editing ? 'tvConfigPageHeaderSetup' : 'tvConfigPageHeader')}: ${
				this.globalState.selectedXboDeviceName
			}`;
		},
	},
	methods: {
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		},
		disableGroupFocus: function () {
			for (const property in this.hasFocus) {
				this.hasFocus[property] = false;
			}
			this.hasFocusApi = false;
		},
		updateButtonsEvents: function (event) {
			this.tvButtonsEvents = event;
		},
		updateHasFocus: function (event) {
			this.hasFocus = event;
		},
		updateHasFocusApi: function (event) {
			this.hasFocusApi = event;
		},
		updateApiKey: function (event) {
			this.apiKey = event;
		},
		getEventNames: function () {
			this.tvButtons().map((button) => {
				const arEvent =
					ar.state.tvConfigData[button.key] && (ar.state.tvConfigData[button.key].eventName || '');
				this.tvButtonsEvents[button.key] = arEvent;
				this.tvButtonsEventsOrig[button.key] = arEvent;
			});
		},
		handleCancelButtonClick: function () {
			this.showTvConfigApiKey ? this.toggleShowTvConfigApiKey() : arRouter.go(-1);
		},
		toggleShowTvConfigApiKey: function () {
			this.showTvConfigApiKey = !this.showTvConfigApiKey;
		},
		handleSaveButtonClick: function () {
			let tvConfigCopy = {};
			tvConfigCopy = Object.assign({}, this.tvConfigData);
			this.tvConfigData = Object.assign({}, tvConfigCopy);
			this.tvConfigData.apiKey = this.apiKey;
			this.hasFocusApi = false;
			this.tvButtons().map((button) => {
				this.tvConfigData[button.key] = { eventName: this.tvButtonsEvents[button.key] };
				this.hasFocus[button.key] = false;
			});

			// Save new buttons
			ar.setState({
				tvConfigData: JSON.parse(JSON.stringify(this.tvConfigData)),
			});
			ar.saveTvConfigData();
			this.tvButtonsEventsOrig = Object.assign({}, this.tvButtonsEvents);
			this.apiKeyOrig = this.apiKey;
			this.setIsNewTVButtonsUser();
			this.showTvConfigApiKey = this.isNewTVButtonsUser;
		},
		setIsNewTVButtonsUser: function () {
			if (this.tvConfigData.apiKey) {
				this.isNewTVButtonsUser = false;
			}
			this.tvButtons().map((button) => {
				if (this.tvButtonsEvents[button.key]) {
					this.isNewTVButtonsUser = false;
				}
			});
		}
	},
	mounted: function () {
		const self = this;
		ar.getTvConfigData(function () {
			self.tvConfigData = JSON.parse(JSON.stringify(ar.state.tvConfigData)); // Deep copy
			self.originalTvConfigJson = JSON.stringify(ar.state.tvConfigData);
			self.apiKey = ar.state.tvConfigData.apiKey || '';
			self.apiKeyOrig = ar.state.tvConfigData.apiKey || '';
			self.getEventNames();
			self.setIsNewTVButtonsUser();
			self.showTvConfigApiKey = self.isNewTVButtonsUser;
			self.isLoading = false;
		});
		window.focusPageHeading();
		window.setPageTitle('tvConfigPageHeader');
	},
};
</script>

<style lang="scss" scoped>
#ar-tvconfig-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'XfinityBrown-Bold';

	#tvbuttons-container {
		margin-left: 1rem;
		margin-right: 1rem;
		margin-bottom: 5rem;
	}

	.eventname-button {
		height: 3.5rem;
		padding: 1rem;
		margin-right: 2rem;
		margin-top: 0.5rem;
	}
}
</style>
