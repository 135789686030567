<template>
	<AccessibleRemote />
</template>

<script>
import AccessibleRemote from '@/components/AccessibleRemote.vue'

export default {
	name: 'app',
	components: {
		AccessibleRemote
	},
	mounted: function() {
		window.pageAlreadyLoaded = true
	},
	beforeUpdate: function() {
		// If user hasn't voiced their opinion w.r.t. tracking consent, get them to
		const consentMode = localStorage.getItem('consentMode');
		if ( consentMode === null ) {
		// Show privacy settings page if the user hasn't given/denied consent
		// But let them at least get to one of the remote pages before showing
			if ( window.location.pathname === '/remote' ||
				 window.location.pathname === '/my-remote' ) {
				arRouter.push('/settings');
			}
		}
	}
}
</script>

<style lang="scss">
	@import "@/scss/main.scss";
</style>
