<template>
	<div id="button-sections" :key="enableTvButtons">
		<div class="button-section">
			<div class="space-between mb-half">
				<TVPower></TVPower>
				<TVInput></TVInput>
				<AllPower></AllPower>
			</div>
			<div class="button-row">
				<Mute></Mute>
				<VolumeDown></VolumeDown>
				<VolumeUp></VolumeUp>
			</div>
			<div class="button-row">
				<ChannelUp></ChannelUp>
				<ChannelDown></ChannelDown>
				<Replay></Replay>
			</div>
			<div class="button-row">
				<Rewind></Rewind>
				<PlayPause></PlayPause>
				<FastForward></FastForward>
			</div>
			<div class="button-row">
				<Exit></Exit>
				<Voice></Voice>
				<Record></Record>
			</div>
			<VoiceCommand></VoiceCommand>
		</div>

		<DPadX1></DPadX1>

		<div class="button-section">
			<div class="space-between mb-half">
				<A></A>
				<B></B>
				<C></C>
				<D></D>
			</div>
			<div class="button-row">
				<One></One>
				<Two></Two>
				<Three></Three>
			</div>
			<div class="button-row">
				<Four></Four>
				<Five></Five>
				<Six></Six>
			</div>
			<div class="button-row">
				<Seven></Seven>
				<Eight></Eight>
				<Nine></Nine>
			</div>
			<div class="button-row flex-end">
				<Zero></Zero>
				<AddPopularButton :showMe="true"></AddPopularButton>
			</div>
		</div>
	</div>
</template>

<script>
import VoiceCommand from './VoiceCommand.vue';
import remoteMixin from './mixins/remote';
/* Buttons */
import A from './buttons/A.vue';
import B from './buttons/B.vue';
import C from './buttons/C.vue';
import D from './buttons/D.vue';
import AddPopularButton from './buttons/AddPopularButton.vue';
import AllPower from './buttons/AllPower.vue';
import ChannelDown from './buttons/ChannelDown.vue';
import ChannelUp from './buttons/ChannelUp.vue';
import DPadX1 from './buttons/DPadX1.vue';
import Eight from './buttons/Eight.vue';
import Exit from './buttons/Exit.vue';
import FastForward from './buttons/FastForward.vue';
import Five from './buttons/Five.vue';
import Four from './buttons/Four.vue';
import Mute from './buttons/Mute.vue';
import Nine from './buttons/Nine.vue';
import One from './buttons/One.vue';
import PlayPause from './buttons/PlayPause.vue';
import Record from './buttons/Record.vue';
import Replay from './buttons/Replay.vue';
import Rewind from './buttons/Rewind.vue';
import Seven from './buttons/Seven.vue';
import Six from './buttons/Six.vue';
import Three from './buttons/Three.vue';
import TVInput from './buttons/TVInput.vue';
import TVPower from './buttons/TVPower.vue';
import Two from './buttons/Two.vue';
import Voice from './buttons/Voice.vue';
import VolumeDown from './buttons/VolumeDown.vue';
import VolumeUp from './buttons/VolumeUp.vue';
import Zero from './buttons/Zero.vue';

export default {
	name: 'X1Remote',
	mixins: [remoteMixin],
	components: {
		A,
		B,
		C,
		D,
		AddPopularButton,
		AllPower,
		ChannelDown,
		ChannelUp,
		DPadX1,
		Eight,
		Exit,
		FastForward,
		Five,
		Four,
		Mute,
		Nine,
		One,
		PlayPause,
		Record,
		Replay,
		Rewind,
		Seven,
		Six,
		Three,
		TVInput,
		TVPower,
		Two,
		Voice,
		VoiceCommand,
		VolumeDown,
		VolumeUp,
		Zero
	}
};
</script>

<style lang="scss" scoped>
	.space-between {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	.mb-half {
		margin-bottom: 0.5rem;
	}
	.flex-end {
		justify-content: flex-end;
		width: 100%;
		margin-right: -1rem;
	}
	.flex-start {
		justify-content: flex-start;
		width: 100%;
	}
</style>
