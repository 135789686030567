<template>
	<div id="choose-popular-buttons-page" class="page">
		<template v-if="menuOpen">
			<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
		</template>
		<Menu></Menu>
		<RemoteHeader v-bind:heading="$t('choosePopularButtonsPageHeader')"></RemoteHeader>
		<div class="">
			<ChoosePopularButtons></ChoosePopularButtons>
		</div>
	</div>
</template>

<script>
import ChoosePopularButtons from '@/components/ChoosePopularButtons.vue';
import RemoteHeader from '@/components/RemoteHeader.vue';
import Menu from '@/components/Menu.vue';

export default {
	name: 'ChoosePopularButtonsPage',
	components: {
    	ChoosePopularButtons,
		RemoteHeader,
        Menu,
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {
		closeMenu: function () {
            if (this.menuOpen) {
                this.menuOpen = false;
            }
        },
	},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('choosePopularButtonsPageHeader');
	}
};
</script>
