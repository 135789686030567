import arRouter from "../../router";

export default {
	data: function () {
		return {
			activeImageItem: null,
			addPopularButtonCommands: [], // Array of commands, not buttons!,
			buttonIndex: 0,
			buttonFocusIndex: 0,
			command: '',
			configError: false,
			currentPopularButtonCategory: 'Apps',
			currentRightCommandPosition: -1,
			deviceWidth: window.innerWidth,
			dialogs: {},
			didFindImage: false,
			displayString: '',
			globalState: ar.state,
			havePopularButtonCommands: [], // Array of commands, not buttons!
			image: '',
			isAddCustomClicked: false,
			isLookingUpImage: false,
			isOKToggled: false,
			isButtonPopular: false,
			originalRightCommandsJson: '[]',
			remPopularButtonCommands: [], // Array of commands, not buttons!
			topButtonsNum: 8,
			directionUp: -6,
			directionUpMobile: -3,
			directionDown: 6,
			directionDownMobile: 3,
			directionLeft: -1,
			directionRight: 1,
			showChooseImageDialog: false
		};
	},
	methods: {
		changeFocus: function (oldItem, newItem) {
			if(oldItem === newItem) { return } // prevents focus from being forced onto the first button when the page initially loads
			[...newItem.parentElement.children].forEach((child) => {
				// Remove all siblings of the new item from the tab order.
				// If we only remove the old item from the tab order, we can run into cases where, if the user navigates to a subsequent sibling using their keyboard, 2 items get put into the tab order. We don't want this.
				if (child !== newItem) {
					child.setAttribute('tabindex','-1');
				}
			});
			newItem.removeAttribute('tabindex'); //add new item back in. since these are buttons, we just have to remove tabindex attr instead of adding tabindex=0
			newItem.focus();
		},

		isActive: function (command) {
			return command === this.currentRightCommand;
		},

		handleOKToggle: function (e) {
			this.isOKToggled = !this.isOKToggled;
		},

		assignDialogRef(type, dialog) {
			this.dialogs[type] = dialog;
		},

		setShowChooseImageDialog: function (value) {
			this.showChooseImageDialog = value;
		},

		selectImage: function (fullImagePath, i) {
			this.activeImageItem = i;
			this.image = fullImagePath;
		},

		handleDeleteClick: function (event) {
			if (this.currentRightCommand && this.currentRightCommandPosition !== -1) {
				this.rightCommands.splice(this.currentRightCommandPosition, 1);
				this.currentRightCommand = null;
				this.currentRightCommandPosition = -1;
			}
		},

		confirmDelete: function (e) {
			if (this.currentRightCommand && this.currentRightCommandPosition !== -1) {
				this.rightCommands.splice(this.currentRightCommandPosition, 1);
				this.currentRightCommand = null;
				this.currentRightCommandPosition = -1;
			}
			this.dialogs['confirmDeleteDialog'].hide();
		},

		cancelDelete: function (e) {
			if (this.dialogs['confirmDeleteDialog']) {
				this.dialogs['confirmDeleteDialog'].hide();
			}
		},

		handleAddClick: function () {
			this.showEditCustomButton = false;
			this.customButtonAddMode = true;
			this.isAddCustomClicked = true;
			window.focusPageHeading(); // Hack to ensure focus goes to the page heading every time this screen appears. Needed because going to this screen from My Buttons doesn't always fire a 'mounted' event.
			window.setPageTitle('customButtonAddPageHeader');
		},

		handleCustomClick: function () {
			this.customButtonAddMode = false;
			this.showEditCustomButton = true;
		},

		// See https://codepen.io/rentringer/pen/eYzVKQm for Move Up or Down position in vue
		handleMove: function (e, direction) {
			this.rightCommands.splice(
				this.currentRightCommandPosition + direction,
				0,
				this.rightCommands.splice(this.currentRightCommandPosition, 1)[0]
			); // Change the order of the custom buttons under the hood
			let newList = this.rightCommands;
			this.currentRightCommandPosition = this.currentRightCommandPosition + direction;
			let buttonFocusOld = this.buttonFocusIndex;
			this.buttonFocusIndex = this.buttonFocusIndex + direction;
			const oldFocus = this.$refs[`seaButton${buttonFocusOld}`][0];
			const newFocus = this.$refs[`seaButton${this.buttonFocusIndex}`][0];
			this.changeFocus(oldFocus, newFocus);
		},

		handleScrollUp: function () {
			if (this.rightCommands) {
				var element = document.querySelector('.custom-buttons-scroll');
				const buttonHeight = document.querySelector('.command-button').offsetHeight;
				element.scrollBy(0, -buttonHeight);
			}
		},

		handleScrollDown: function () {
			if (this.rightCommands) {
				var element = document.querySelector('.custom-buttons-scroll');
				const buttonHeight = document.querySelector('.command-button').offsetHeight;
				element.scrollBy(0, buttonHeight);
			}
		},

		handleSaveButtonClick: function () {
			// Save new buttons
			if (!this.isSaveDisabled) {
				ar.setState({
					seaButtonsData: JSON.parse(JSON.stringify(this.rightCommands)),
					menuOpen: false
				});
				ar.saveSeaButtonsData();
			}
			arRouter.push('/my-remote');
		},

		handleCancelButtonClick: function () {
			// Just go back; no change to ar.state.seaButtons
			arRouter.push('/my-remote');
		},

		handleRightClick: function (ev, command, i) {
			this.isButtonPopular = this.globalState.popularSeaButtonsFlat.some((button) => command.command === button.command)
			this.buttonFocusIndex = i;
			if (!this.globalState.seaButtonsData) {
				return;
			}
			this.currentRightCommand = command;
			this.currentRightCommandPosition = i;
			this.configError = false;

			const self = this;
			const oldTarget = ev.currentTarget.parentElement.querySelector("[role='option']:not([tabindex='-1'])");
			self.changeFocus(
				oldTarget,
				ev.currentTarget
			);

			// now, change aria-selected. this will account for enter and space automatically since the listbox options
			// are made out of buttons only change selection if clicking something other than the currently-selected option
			if (ev.target.getAttribute('aria-selected') === 'false') {
				ev.currentTarget.parentElement.querySelector("[aria-selected='true']")
					? ev.currentTarget.parentElement
							.querySelector("[aria-selected='true']")
							.setAttribute('aria-selected', 'false')
					: ''; // unselect whichever is selected, or do nothing if no elements are selected
				ev.currentTarget.setAttribute('aria-selected', 'true'); //select the target one
			}
		},

		getDirection: function (direction) {
			const firstRowDesktop = this.buttonFocusIndex < 8;
			const secondRowDesktop = this.buttonFocusIndex > 7 && this.buttonFocusIndex < 14;
			if (this.isMobile) {
				if (direction === this.directionDown) {
					direction = this.directionDownMobile;
				}

				if (direction === this.directionUp) {
					direction = this.directionUpMobile;
				}
			} else {
				direction =
					direction === this.directionDown && firstRowDesktop ? direction + 2 : direction;
				direction =
					direction === this.directionUp && secondRowDesktop ? direction - 2 : direction;
			}

			return direction;
		},

		handleFocus: function (e, direction) {
			const moveHere =
				this.buttonFocusIndex + direction > this.rightCommands.length
					? this.rightCommands.length - 1
					: this.buttonFocusIndex + direction;
			const newFocus = this.$refs[`seaButton${moveHere}`][0];

			this.changeFocus(this.$refs[`seaButton${this.buttonFocusIndex}`][0], newFocus);
			this.buttonFocusIndex = moveHere;
			newFocus.click();
		},

		handleUpKey: function (e) {
			if (e.currentTarget.previousElementSibling) {
				this.changeFocus(e.currentTarget, e.currentTarget.previousElementSibling);
			} else {
				this.changeFocus(
					e.currentTarget,
					e.currentTarget.parentElement.children[
						e.currentTarget.parentElement.children.length - 1
					]
				);
			}
		},

		handleDownKey: function (e) {
			if (e.currentTarget.nextElementSibling) {
				this.changeFocus(e.currentTarget, e.currentTarget.nextElementSibling);
			} else {
				this.changeFocus(e.currentTarget, e.currentTarget.parentElement.children[0]);
			}
		},

		handleWindowResize: function () {
			this.deviceWidth = window.innerWidth;
		},

		safeImage: function (imageSrc) {
			return imageSrc || '/images/seabuttons/question-mark.png';
		},

		fullImagePath: function (imgFileName) {
			return `/images/seabuttons/adhoc-icons/${imgFileName}`;
		},

		calcHavePopularButtonCommandsBasedOnUsersButtons: function () {
			this.havePopularButtonCommands = [];
			const rightCommands = this.rightCommands;
			const popularButtons = this.globalState.popularSeaButtons;

			for (let rr = 0; rr < rightCommands.length; rr += 1) {
				let rightCommand = rightCommands[rr];
				for (let pcc = 0; pcc < popularButtons.length; pcc += 1) {
					let popularCategory = popularButtons[pcc];
					for (let pbb = 0; pbb < popularCategory.buttons.length; pbb += 1) {
						let popularButton = popularCategory.buttons[pbb];
						if (rightCommand.command === popularButton.command) {
							this.havePopularButtonCommands.push(rightCommand.command);
						}
					}
				}
			}
		},

		resetPopularButtonsLists: function () {
			this.addPopularButtonCommands = [];
			this.remPopularButtonCommands = [];
			this.calcHavePopularButtonCommandsBasedOnUsersButtons();
		},

		setButtonIndex: function (index) {
			this.buttonIndex = index;
		},

		handleEditPopularClick: function () {
			arRouter.push('/my-remote-config');
		},

		handleFullRemoteClick: function () {
			arRouter.push('/remote');
		},

		handleAddPopularClick: function () {
			arRouter.push('/add-popular-buttons');
		},

		handleAddButtonClick: function () {
			arRouter.push('/add-new-button');
		},

		handleViewAll: function () {
			arRouter.push('/my-remote');
		},

		updateCustomButtonEvent: function (event) {
			this.currentRightCommand = event;
		},

		updateShowCustomButton: function (event) {
			this.showEditCustomButton = event;
		},

		updateCustomButtonAddMode: function (event) {
			this.customButtonAddMode = event;
		},

		updateAddCustomClicked: function (event) {
			this.isAddCustomClicked = event
		}
	},
	computed: {
		adhocImages: function () {
			return [
				{ src: 'aqua-radial-square.png', alt: 'Aqua radial square' },
				{ src: 'green-square.png', alt: 'Green square' },
				{ src: 'purple-radial-square.png', alt: 'Purple radial square' },
				{ src: 'blue-radial-square.png', alt: 'Blue radial square' },
				{ src: 'orange-square.png', alt: 'Orange square' },
				{ src: 'red-square.png', alt: 'Red square' },
				{ src: 'blue-square.png', alt: 'Blue square' },
				{ src: 'pink-radial-square.png', alt: 'Pink radial square' },
				{ src: 'yellow-radial-square.png', alt: 'Yellow radial square' },
				{ src: 'green-radial-square.png', alt: 'Green radial square' },
				{ src: 'pink-square.png', alt: 'Pink square' },
				{ src: 'yellow-square.png', alt: 'Yellow square' }
			];
		},
		onSeaButtonsPage: function () {
			return this.$route.name === 'seaButtonsPage';
		},
		onEditButtonOrderPage: function () {
			return this.$route.name === 'editButtonOrderPage';
		},
		disableScroll: function () {
			return this.rightCommands.length < (this.isMobile ? 18 : 27);
		},
		shouldShowChooseImageButton: function () {
			//return ! this.didFindImage;
			return true;
		},
		isMobile: function () {
			return this.deviceWidth <= 960;
		},
		isButtonWithinAddEditDialogActive: function () {
			if (this.command && this.displayString && this.image) {
				return true;
			}
			return false;
		},
		isSaveDisabled: function () {
			return this.originalRightCommandsJson === JSON.stringify(this.rightCommands);
		},
		isEditVisible: function () {
			return !this.isButtonPopular;
		},
		isEditDisabled: function () {
			return !this.currentRightCommand;
		},
		isUpDownVisible: function () {
			return true;
			//return ( this.rightCommands.length !== 0 );
		},
		isNavDisabledFirst: function () {
			return !(this.currentRightCommand && this.currentRightCommandPosition !== 0);
		},
		isNavDisabledLast: function () {
			return !(
				this.currentRightCommand &&
				this.currentRightCommandPosition !== this.rightCommands.length - 1
			);
		},
		isDeleteVisible: function () {
			return true;
			//return ( this.rightCommands.length !== 0 );
		},
		isDeleteDisabled: function () {
			return !this.currentRightCommand;
		},
		headingLabel: function () {
			let headingLabel = ar.i18nJ.t('editButtonOrderPageHeader');
			
			if (this.isAddCustomClicked) {
				headingLabel = ar.i18nJ.t('customButtonAddPageHeader');
			}

			if (this.showEditCustomButton) {
				headingLabel = ar.i18nJ.t(this.customButtonAddMode ?  'customButtonPageAddHeader' : 'customButtonPageHeader');
			}
			return headingLabel;
		},
		buttonsInCurrentCategory: function () {
			if (!this.currentPopularButtonCategory) {
				this.currentPopularButtonCategory = 'Apps';
			}
			const currentCats = this.globalState.popularSeaButtons.filter((cat) => {
				return cat.category === this.currentPopularButtonCategory;
			});
			if (currentCats && currentCats.length > 0) {
				return currentCats[0].buttons;
			}
			return []; // Ugh
		}
	}
};
