<template>
	<div id="edit-custom-buttons-page" class="page">
		<div id="custom-buttons-config" class="">
			<template v-if="globalState.menuOpen">
				<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
			</template>
			<Menu></Menu>
			<RemoteHeader :heading="$t('customButtonPageAddHeader')"></RemoteHeader>
			<EditCustomButton
				:custom-button-add-mode="customButtonAddMode"
				:current-right-command="currentRightCommand"
				:right-commands="rightCommands"
			/>
		</div>
	</div>
</template>

<script>
import RemoteHeader from "@/components/RemoteHeader.vue";
import editButtonsMixin from "@/components/mixins/editButtons";
import EditCustomButton from "@/components/EditCustomButton.vue";
import Menu from '@/components/Menu.vue';

export default {
	name: "EditCustomButtonPage",
	mixins: [editButtonsMixin],
	components: {
		RemoteHeader,
		EditCustomButton,
		Menu
	},
	data: function () {
		return {
			customButtonAddMode: true,
			currentRightCommand: null,
			rightCommands: [],
			showEditCustomButton: false,
		};
	},
	methods: {
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		}
	},
	mounted: function () {
		const self = this;

		ar.setState({
			customMode: true,
		});
		if (ar.isLocalStorageAvailable("localStorage")) {
			ar.saveLocal("customMode", "true");
		}

		// Catch for popular buttons on app init.
		ar.getPopularSeaButtons();

		// Fill right list of selected custom buttons, fetching if necessary (not typical)
		if (!this.globalState.haveSeaButtonsData) {
			ar.getSeaButtonsData(function () {
				if (ar.state.seaButtonsData.length > 0) {
					self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
					self.originalRightCommandsJson = JSON.stringify(ar.state.seaButtonsData); // Deep copy
				}
			});
		} else {
			if (ar.state.seaButtonsData.length > 0) {
				self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
				self.originalRightCommandsJson = JSON.stringify(ar.state.seaButtonsData); // Deep copy
			}
		}
		window.focusPageHeading();
		window.setPageTitle('customButtonPageAddHeader');
	},
};
</script>

<style lang="scss" scoped>
/* ---------- */

#edit-custom-buttons-page {
	display: flex;
	flex-direction: column;
	background-color: $ar-background-color;
	height: 100vh;
}

#custom-buttons-config {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $ar-background-color;

	@include lg {
		align-items: flex-start;
	}
}
</style>
