<template>
	<div id="tvconf-input-container">
		<div class="tvconf-apikey-input">
			<h2>{{ $t('tvConfigPageKeyGetStarted') }}</h2>
			<label class="label" for="apikey-input">{{ $t('tvConfigPageKeyLabel') }}</label>
			<div id="apikey-group">
				<span
					class="apikey-value"
					tabindex="0"
					v-on:focus.stop="setFocusApi"
					v-show="!getFocusApi"
					>{{ apiKey ? apiKey : $t('tvConfigPageConfigApiPlaceholder') }}
				</span>
				<input
					:class="{ hide: !getFocusApi }"
					ref="apikeyInput"
					id="apikey-input"
					:placeholder="$t('tvConfigPageConfigApiPlaceholder')"
					name="apikey"
					type="text"
					v-on:click.stop="setFocusApi"
					v-model="updateApiKey"
				/>
				<button
					v-show="getFocusApi"
					class="clear-button standard-button primary"
					:disabled="!updateApiKey"
					v-on:click="handleClearButtonClickAPIKey"
				>
					{{ $t('tvConfigPageConfigClear') }}
				</button>
			</div>
		</div>
		<div id="tvconf-links">
			<a href="https://ifttt.com/maker_webhooks" target="_blank">
				{{ $t('tvConfigPageConfigCopyKeyLink') }}
				<svg
					class="svg"
					focusable="false"
					aria-hidden="true"
					viewBox="0 0 24 24"
				>
					<path
						d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
					></path>
				</svg>
			</a>
			<a href="https://webremote.com/docs/ifttt/howto.html" target="_blank">
				{{ $t('tvConfigPageConfigDocsLink') }}
				<svg
					class="svg"
					focusable="false"
					aria-hidden="true"
					viewBox="0 0 24 24"
				>
					<path
						d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
					></path>
				</svg>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	props: ['apiKey', 'hasFocusApi'],
	name: 'TVConfigAPIKey',
	data: function () {
		return {
			isFocused: this.hasFocusApi,
		};
	},
	computed: {
		updateApiKey: {
			get() {
				return this.apiKey;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		getFocusApi: function () {
			return this.hasFocusApi;
		},
	},
	methods: {
		handleClearButtonClickAPIKey: function () {
			this.$emit('input', '');
		},
		setFocusApi: function () {
			this.isFocused = true;
			this.$emit('update-has-focus-api', this.isFocused);
			this.$refs.apikeyInput.focus();
		},
	},
	mounted: function() {
		window.focusPageHeading("h2");
	}
};
</script>

<style lang="scss" scoped>
#ar-tvconfig-page {
	#tvconf-links {
		display: flex;
		flex-direction: column;

		a {
			display: flex;
			align-items: center;
			font-size: 1.25rem;
			margin-bottom: 1.5rem;
			max-width: 50%;
			@include focus-pseudo-class;

			svg {
				height: 1.5rem;
				width: 1.5rem;
				margin: 0;
				margin-left: 0.25rem;
			}
		}
	}

	.tvconf-apikey-input {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 2.5rem;

		#apikey-input {
			height: 3.5rem;
			border: 0;
			text-indent: 1rem;
			border-radius: 0.25rem;
			font-size: 1rem;
			color: $xfinity-grey-light;
			background-color: $xfinity-grey-lighter;
			@include focus-pseudo-class;

			@include lg {
				width: 100%;
			}
		}

		#apikey-group {
			font-size: 1rem;
			display: flex;
			flex-direction: column;

			.apikey-value {
				font-family: 'DMSans-Regular';
				height: 3.5rem;
				display: flex;
				align-items: center;
				border-bottom: 1px white solid;
			}

			.clear-button {
				max-width: 10rem;
				margin-top: 1rem;
				border: 0;

				@include lg {
					margin-top: 0;
					margin-left: 1rem;
				}
			}

			@include lg {
				flex-direction: row;
				min-width: 40rem;
			}
		}

		.label {
			font-family: 'XfinityBrown-Regular';
			font-size: 1rem;
			margin-bottom: 1rem;
		}

		.checkmark {
			position: relative;
			left: 1.8rem;
		}

		#event-name {
			font-family: monospace;
			font-size: 1.2rem;
		}
	}

	#tvconf-input-container {
		color: white;
	}
}
</style>
