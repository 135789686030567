<template>
    <button
        id="ACCESSIBILITY"
        name="ACCESSIBILITY"
        class="btn"
        :data-command="$t('accessibility')"
    >
        <img src="/images/icons/accessibility.svg" alt="" role="img" aria-hidden="true" />
        <label>{{ $t('accessibility') }}</label>
    </button>
</template>
<script>
export default {};
</script>
