<template>
	<PleaseWait></PleaseWait>
</template>

<script>
import i18nJ from '@/plugins/i18nJ';
import PleaseWait from '@/components/PleaseWait.vue';

export default {
	name: 'LoginCompletePage',
	components: {
		PleaseWait
	},
	props: [],
	data: function() {
		return {};
	},
	methods: {},
	computed: {},
	mounted: function() {
		// Handy for debugging Xerxes-related issues:
		//console.log('Xerxes redirected to: ' + window.location.href);

		const url = window.location.href.replace('#', '?');

		const err = ar.getParameterByName('error', url);

		if ( err ) {
			const errDesc = ar.getParameterByName('error_description', url);
			const activityId = ar.getParameterByName('activity_id', url);
			let msg = i18nJ.t('loginError') + ': ' + err;
			if ( errDesc ) { msg += ' (' + errDesc + ')'; }
			if ( activityId ) { msg += ' [' + activityId + ']'; }
			arRouter.push('/error?msg=' + msg);
			return;
		}

		const code = ar.getParameterByName('code', url);
		let partner = ar.getPartner();
		ar.setState({ partner });
		ar.fetchTokenFromAuthCode(code);
		window.focusPageHeading();
	}
};
</script>
