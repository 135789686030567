<template>
	<div class="custom-buttons-container">
	<div id="custom-buttons-config-main">
		<h2 class="h1">{{ $t("customButtonPageLede") }}</h2>
		<div class="custom-buttons-sub-lede" role="heading" aria-level="3">
			<span id="custom-buttons-sub-lede-number">1</span>
			<span id="custom-buttons-sub-lede-text">{{ $t('customButtonPageCommandLede') }}</span>
		</div>
		<div>

			<p v-show="configError" id="configErrorElement" class="error">
				{{ this.configError ? $t("editButtonOrderPageConfigError") : "" }}
			</p>

			<div class="command-input">
				<div class="command-input-group">
					<div class="input-row">
						<label for="command" class="enter-label">{{ $t("editButtonOrderPageEnterCommand") }} :</label>
						<input type="text" id="command" name="command" v-model="command" @blur="handleCommandBlur"
							 @change="handleCommandChange" aria-required="true"
							:aria-invalid="configError" aria-describedby="configErrorElement" ref="commandinput"/>
					</div>
					<button
						class="eventname-button standard-button secondary"
						@click.stop="handleClearButtonClickEvent"
						:aria-label="`${$t('tvConfigPageConfigClear')} command`"
					>
						{{ $t('tvConfigPageConfigClear') }}
					</button>
				</div>
				<div class="input-row" id="input-row-right">
					<label for="displayString" class="enter-label">{{ $t("editButtonOrderPageEnterDispStr")
					}}:</label>
					<input type="text" id="displayString" name="displayString" v-model="displayString" :aria-invalid="configError" aria-describedby="configErrorElement"  ref="labelinput" />
				</div>
			</div>
			<div class="image-selection-container">
				<div class="left">
					<div class="custom-buttons-sub-lede mb-5" role="heading" aria-level="3">
						<span id="custom-buttons-sub-lede-number">2</span>
						<span id="custom-buttons-sub-lede-text">{{ $t('customButtonPageImageLede') }}</span>
					</div>
					<div id="image-selection">
						<div class="slider" role="region" :aria-label="`${$t('editButtonOrderPageChooseImage')} selection carousel`">
							<button class="slide-arrow" id="slide-arrow-prev" @click="moveLeft" :aria-label="`Scroll ${$t('editButtonOrderPageChooseImage')} selection carousel ${$t('leftArrow')}`">
								&#8249;
							</button>
							<button class="slide-arrow" id="slide-arrow-next" @click="moveRight" :aria-label="`Scroll ${$t('editButtonOrderPageChooseImage')} selection carousel ${$t('rightArrow')}`">
								&#8250;
							</button>
							<span class="custom-image-heading h3 mb-3">{{ $t("editButtonOrderPageChooseImage")
							}}</span>
							<div class="custom-image-container" ref="imgcarousel">
								<span v-for="(img, i) in adhocImages" :key="i">
									<button @click="selectImage(fullImagePath(img.src), i)"
										class="custom-image-link" role="checkbox" :aria-checked="`${i === activeImageItem}`">
										<img :src="fullImagePath(img.src)" ref="customimage" class="custom-image"
											:class="{ 'custom-image-active': i === activeImageItem || imageSelected(fullImagePath(img.src)) }"
											:alt="''" />
										<span class="custom-image-label">{{ img.alt }}</span>
									</button>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="input-row">
					<div class="button-preview">
						<template v-if="!command">
							<p class="command-error">
								{{ $t("editButtonOrderPageEnterCommandErr") }}
							</p>
						</template>
						<template v-else-if="isLookingUpImage">
							<p class="please-wait">
								{{ $t("editButtonOrderPagePleaseWait") }}...
							</p>
						</template>
						<template v-else-if="image">
							<span class="custom-image-heading h3 mb-8" role="heading" aria-level="3">{{ $t("editButtonOrderPageTestButton")
							}}</span>
							<div class="button-preview-test">
								<button class="btn" :data-command="command" @click.stop="handleTestButtonClick">
									<img :src="image" alt=""/>
									<label v-html="displayString"></label>
								</button>
								<button
									class="eventname-button standard-button test-command-button"
									@click.stop="handleTestButtonClick"
									:aria-label="`${$t('tvConfigPageConfigTest')} command`"
								>
									{{ $t('tvConfigPageConfigTest') }}
								</button>
							</div>
						</template>
					</div>
				</div>

			</div>
			<div class="save-button-area">
				<button class="text-command-button standard-button primary modal-button" @click="handleOkay">
					{{ $t("customButtonPageSave") }}
				</button>
				<button class="text-command-button standard-button secondary modal-button" @click="handleCancel">
					{{ $t("editButtonOrderPageCancel") }}
				</button>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import RemoteHeader from "@/components/RemoteHeader.vue";
import editButtonsMixin from "@/components/mixins/editButtons";
import arRouter from "../router";

export default {
	props: ["customButtonAddMode", "currentRightCommand", "rightCommands"],
	name: "EditCustomButton",
	mixins: [editButtonsMixin],
	components: {
		RemoteHeader,
	},
	data: function () {
		return {
			slideWidth: 70
		}
	},
	beforeDestroy: function () {
		window.removeEventListener("resize", this.handleWindowResize);
	},
	methods: {
		moveLeft: function () {
			this.$refs.imgcarousel.scrollLeft -= this.slideWidth;
		},
		moveRight: function () {
			this.$refs.imgcarousel.scrollLeft += this.slideWidth;
		},

		handleClearButtonClickEvent: function () {
			this.command = '';
			this.displayString = '';
		},

		handleTestButtonClick: function () {
			// Beep if sound is on
			if (ar.state.keySound) {
				ar.beep();
			}
			// Process the key
			ar.submitTextCommand(this.command);
		},

		handleCommandBlur: function () {
			const self = this;
			this.isLookingUpImage = true;
			if (this.command && this.command.length > 0) {
				this.configError = false;
			}
			ar.getSeaButtonImage(this.command, function (err, data) {
				if (err) {
					// Treat an error quietly; just show the "choose image" experience
					self.isLookingUpImage = false;
					self.didFindImage = false;
					self.image = null;
				} else {
					self.isLookingUpImage = false;
					if (data.image) {
						self.didFindImage = true;
						self.image = data.image;
					} else {
						self.didFindImage = false;
						self.image = null;
					}
				}
			});
		},

		handleCommandChange: function () {
			if (!this.displayString) {
				this.displayString = this.command;
			}
		},

		handleOkay: function (e) {
			if (!this.isButtonWithinAddEditDialogActive) {
				this.configError = true;
				let focusTgt = this.command.length === 0 ? this.$refs.commandinput : this.$refs.labelinput;
				focusTgt.focus();

			} else {
				if (this.customButtonAddMode) {
					// Adds the command to the start of the list
					// Might be better to push to end of list, but then we'd need to scroll to the bottom
					this.rightCommands.unshift({
						type: "VOICE",
						command: this.command,
						displayString: this.displayString,
						image: this.image,
					});
				}
				else {
					if (!this.currentRightCommand) {
						console.log(
							"INTERNAL ERROR: Edit button in Add/Edit dialog did not find a current button to edit"
						);
						return;
					}

					// Update the current right command/button
					let currentCommand = this.rightCommands.find(button => button.command === this.currentRightCommand.command)
					if (currentCommand) {
						currentCommand.command = this.command;
						currentCommand.displayString = this.displayString;
						currentCommand.image = this.image;
					}

				}

				this.command = "";
				this.displayString = "";
				this.image = "";
				this.isLookingUpImage = false;
				this.didFindImage = false;
				this.configError = false;

				// Save new buttons
				if (!this.isSaveDisabled) {
					ar.setState({
						seaButtonsData: JSON.parse(JSON.stringify(this.rightCommands)),
						menuOpen: false
					});
					ar.saveSeaButtonsData();
				}
				this.$emit("update-custom-button-event", this.rightCommands);
				this.$emit("update-show-custom-button", false);
				this.$emit("update-add-custom-clicked", false);
				this.$route.name !== 'editButtonOrderPage' && arRouter.push('/my-remote')
			}
		},

		handleCancel: function () {
			this.command = "";
			this.displayString = "";
			this.image = "";
			this.isLookingUpImage = false;
			this.didFindImage = false;

			this.$emit("update-show-custom-button", false);
			this.$emit("update-add-custom-clicked", false);
			this.$route.name !== 'editButtonOrderPage' && arRouter.push('/my-remote')
		},

		imageSelected: function (image) {
			const currentImage = this.currentRightCommand && this.currentRightCommand.image;
			return image === currentImage;
		}
	},
	mounted: function () {
		const self = this;
		this.$nextTick(() => {
			window.addEventListener("resize", this.handleWindowResize);

			if (!self.currentRightCommand) {
				console.log('INTERNAL ERROR: Edit button did not find a current button to edit');
				return;
			}
			if (!self.customButtonAddMode) {
				self.command = this.currentRightCommand.command;
				self.displayString = this.currentRightCommand.displayString;
				self.image = this.currentRightCommand.image;
			}
			self.isLookingUpImage = false;
			self.didFindImage = false;

			const selectedImageElem = this.$refs.customimage && this.$refs.customimage.find((image) => image.classList.contains('custom-image-active'));
			const imgSliderContainer = this.$refs.imgcarousel;
			const imgHPos = selectedImageElem && selectedImageElem.getBoundingClientRect().x;
			const slideHPos = imgSliderContainer && imgSliderContainer.getBoundingClientRect().x
			imgSliderContainer && (imgSliderContainer.scrollLeft += (imgHPos - slideHPos - 4))
		});
	},
};
</script>

<style lang="scss" scoped>
.custom-buttons-container {
	display: flex;
	justify-content: center;
	align-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	height: 100vh;
	background-color: $ar-background-color;
}
.eventname-button {
	align-items: center;
	display: flex;
	margin-left: 1rem;
	margin-right: 0;
	margin-top: 1rem;
	background: transparent;
	color: white;

	&:focus:enabled,
	&:disabled {
		background-color: $xfinity-grey-dark;
		color: $xfinity-grey-lighter;
	}
}
/* ---------- */
.modal-button {

}

#custom-buttons-config {
	display: flex;
	flex-direction: column;
	background-color: $ar-background-color;
}

#custom-buttons-config-main {
	display: flex;
	flex-direction: column;
	align-self: center;
	margin-top: 1.25rem;
	margin-left: 2rem;
	margin-right: 2rem;

	@include lg {
		align-items: flex-start;
	}
}

#image-selection {
	margin-left: 1rem;
	margin-right: 1rem;
}

#command,
#displayString {
	height: 1.5rem;
	font-size: 1rem;
	font-family: 'DMSans-Regular';
	color: $xfinity-grey-light;
	padding: 1rem;
}

#input-row-right {
	@include lg {
		margin-left: 11.5rem;
	}
}

.image-selection-container {
	display: flex;
	flex-direction: column;

	@include lg {
		flex-direction: row;
		.left {
			margin-right: 12.5rem;
		}
	}
}

.button-preview {
	margin-top: 2.25rem;
}

.command-input {
	display: flex;
	flex-direction: column;;
	align-items: flex-start;
	margin-left: 1rem;
	margin-bottom: 1rem;

	.command-input-group {
		display: flex;
		margin-bottom: 1rem;
	}

	@include lg {
		align-items: center;
		flex-direction: row;
		margin-bottom: 3rem;
	}
}

.custom-buttons-sub-lede {
	display: flex;
	align-items: center;
	margin-top: 1.375rem;
	margin-bottom: 1.375rem;

	#custom-buttons-sub-lede-number {
		margin-top: 0.25rem;
		font-size: 2.25rem;
	}

	#custom-buttons-sub-lede-text {
		font-family: 'DMSans-Regular';
		margin-left: 1.375rem;
	}

}

.error {
	color: #ffff00;
	font-family: XfinityStandard-bold;
}

.enter-label {
	font-size: 0.75rem;
	font-family: 'DMSans-Regular';
}

.input-row input {
	display: block;
	width: 15rem;
	border: 1px solid $xfinity-grey-80;
	/* soft black */
	border-radius: 5px;
	height: 20px;
	padding: 0.5rem;
	@include focus-pseudo-class;
}

.input-row input[aria-invalid="true"] {
	border: 2px solid red;
}

.save-button-area {
	margin-bottom: 4rem;
	.text-command-button {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 2rem;

		&[aria-disabled="true"] {
			background-color: gray;
			border: 2px solid gray;
			cursor: default;
		}
	}

	@include lg {
		padding: 1rem 0;
		margin-left: 1rem;
		margin-bottom: 2.5rem;
	}
}

.finish-actions {
	margin-top: 1.25rem;
}

.custom-image-heading {
	display: block;
}

.slider {
	position: relative;
	width: 23.75rem;
	padding-right: 0.3125rem;

	.custom-image-container {
		display: flex;
		overflow: scroll;
		padding-top: 0.3125rem;
		padding-left: 0.3125rem;
		&::-webkit-scrollbar{
			width: 0;
		}
	}

	.custom-image-link {
		padding: 0;
		border: none;
		margin-right: 0.625rem;
		background-color: transparent;
		&:focus {
			outline: none;
			img {
			@include focus-outline;
		}
		}
		
	}

	.custom-image-label {
		color: white;
		font-family: 'XfinityBrown-Bold';
	}

	.custom-image-active {
		border: 0.3125rem solid $xfinity-yellow-25;
	}

	.custom-image {
		border-radius: 0.3125rem;
		width: 6.25rem;
		height: 3.75rem;

		&:hover {
			@include focus-outline;
		}
	}

	.slide-arrow {
		position: absolute;
		display: flex;
		align-items: center;
		height: 80%;
		color: white;
		background-color: transparent;
		border: none;
		font-size: 4rem;
		padding: 0;
		margin: 0.5rem;
		cursor: pointer;
		opacity: 0.5;
		transition: opacity 100ms;

		@include lg {
			font-size: 2rem;
		}
	}

	.slide-arrow:hover,
	.slide-arrow:focus {
		opacity: 1;
	}

	.slide-arrow:focus {
		@include focus-outline;
	}

	#slide-arrow-prev {
		left: -2rem;
	}

	#slide-arrow-next {
		right: -2rem;
	}
}

.button-preview-test {
	display: flex;
	justify-content: center;
	align-items: center;
	& .test-command-button {
		margin-top: 0;
	}
}
</style>
