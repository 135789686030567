<template>
	<PleaseWait></PleaseWait>
</template>

<script>
import PleaseWait from '@/components/PleaseWait.vue';

export default {
	name: 'LogoutCompletePage',
	components: {
		PleaseWait
	},
	props: [],
	data: function() {
		return {};
	},
	methods: {},
	computed: {},
	mounted: function() {
		// We shouldn't have to focus on the page heading here
		ar.logout(); // Will redirect to home page
	}
};
</script>
