<template>
    <div id="choose-popular-buttons-dialog-main">
        <div class="choose-popular-buttons-header">
            <span>{{$t('choosePopularButtonsPageDescription')}}</span>
        </div>
        <div class="choose-popular-buttons-nav">
            <div
                id="choose-popular-buttons-tabs">
                <ul
                    role="tablist"
                    :aria-label="$t('editButtonOrderPageCategories')">
                    <li
                        v-for="cat in globalState.popularSeaButtons"
                        :key="cat.category"
                        :id="cat.category"
                        :aria-selected="isCategoryActive(cat) ? 'true' : 'false'"
                        aria-controls="choose-popular-buttons-buttons"
                        role="tab"
                        class="popular-button-tab"
                        :class="{ 'active-popular-button-tab': isCategoryActive(cat) }"
                        @click="handlePopularButtonCategoryClick(cat)"
                        @keydown.enter="handlePopularButtonCategoryClick(cat)"
                        @keydown.space.prevent="handlePopularButtonCategoryClick(cat)"
                        tabindex="0"
                    >
                        {{ cat.category }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="choose-popular-buttons-group">
            <div
                id="choose-popular-buttons-buttons"
                role="tabpanel"
                :aria-label="this.currentPopularButtonCategory"
            >
                <button
                    v-for="but in buttonsInCurrentCategory"
                    class="btn"
                    :class="{ 'selected-popular-button': isSelectedPopularCommand(but.command) }"
                    @click="handleButtonClick(but)"
                    :data-command="but.command"
                    role="checkbox"
                    :aria-checked="String(isSelectedPopularCommand(but.command))"
                    :key="but.command"
                >
                    <img :class="{'soft-img': but.isLegacyIcon}" :alt="''" :src="safeImage(but.image)" role="img" aria-hidden="true" />
                    <label v-html="but.displayString"></label>
                </button>
            </div>
            <div id="choose-popular-buttons-actions">
                <button
                    class="standard-button primary"
                    @click="handleOkClick"
                >
                    {{ $t('editButtonOrderPageOkay') }}
                </button>
                <button
                    class="standard-button secondary"
                    @click="handleCancelClick"
                >
                    {{ $t('editButtonOrderPageCancel') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChoosePopularButtons',
    props: [],
    data: function () {
        return {
            addPopularButtonCommands: [], // Array of commands, not buttons!,
            buttonIndex: 0,
            buttonFocusIndex: 0,
            command: '',
            configError: false,
            currentPopularButtonCategory: 'Apps',
            currentRightCommand: null,
            currentRightCommandPosition: -1,
            deviceWidth: window.innerWidth,
            dialogs: {},
            didFindImage: false,
            displayString: '',
            globalState: ar.state,
            havePopularButtonCommands: [], // Array of commands, not buttons!
            image: '',
            isLookingUpImage: false,
            isOKToggled: false,
            originalRightCommandsJson: '[]',
            remPopularButtonCommands: [], // Array of commands, not buttons!
            rightCommands: [],
            directionUp: -6,
            directionUpMobile: -3,
            directionDown: 6,
            directionDownMobile: 3,
            directionLeft: -1,
            directionRight: 1,
        };
    },
    methods: {
        safeImage: function (imageSrc) {
            return imageSrc || '/images/seabuttons/question-mark.png';
        },

        calcHavePopularButtonCommandsBasedOnUsersButtons: function () {
            this.havePopularButtonCommands = [];
            const rightCommands = this.rightCommands;
            const popularButtons = this.globalState.popularSeaButtons;

            for (let rr = 0; rr < rightCommands.length; rr += 1) {
                let rightCommand = rightCommands[rr];
                for (let pcc = 0; pcc < popularButtons.length; pcc += 1) {
                    let popularCategory = popularButtons[pcc];
                    for (let pbb = 0; pbb < popularCategory.buttons.length; pbb += 1) {
                        let popularButton = popularCategory.buttons[pbb];
                        if (rightCommand.command === popularButton.command) {
                            this.havePopularButtonCommands.push(rightCommand.command);
                        }
                    }
                }
            }
        },

        resetPopularButtonsLists: function () {
            this.addPopularButtonCommands = [];
            this.remPopularButtonCommands = [];
            this.calcHavePopularButtonCommandsBasedOnUsersButtons();
        },

        findPopularButtonByCommand: function (command) {
            const popularButtons = this.globalState.popularSeaButtons;
            let button = null;
            for (let pcc = 0; pcc < popularButtons.length; pcc += 1) {
                let popularCategory = popularButtons[pcc];
                for (let pbb = 0; pbb < popularCategory.buttons.length; pbb += 1) {
                    let popularButton = popularCategory.buttons[pbb];
                    if (popularButton.command.toLowerCase() === command.toLowerCase()) {
                        button = popularButton;
                    }
                }
            }
            return button;
        },

        findRightButtonPositionByCommand: function (command) {
            const rightButtons = this.rightCommands;
            let buttonPosition = -1;
            for (let rr = 0; rr < rightButtons.length; rr += 1) {
                let rightButton = rightButtons[rr];
                if (rightButton.command.toLowerCase() === command.toLowerCase()) {
                    buttonPosition = rr;
                }
            }
            return buttonPosition;
        },

        isSelectedPopularCommand: function (command) {
            // Should the checkmark appear on the popular button with the given command?
            // Yes: command is on the add list
            // Yes: command is on the have list and is NOT on the remove list
            // No:  otherwise
            if (this.addPopularButtonCommands.includes(command)) {
                return true;
            }
            if (
                this.havePopularButtonCommands.includes(command) &&
                !this.remPopularButtonCommands.includes(command)
            ) {
                return true;
            }
            return false;
        },

        handleButtonClick: function (but) {
            // Maintain one of three states for each command:
            // - on have list and keep (not on remove list)
            // - on have list and remove (on remove list)
            // - not on have list and add (on add list)

            const command = but.command;
            let index;
            if (this.havePopularButtonCommands.includes(command)) {
                index = this.remPopularButtonCommands.indexOf(command);
                if (index > -1) {
                    this.remPopularButtonCommands.splice(index, 1);
                } else {
                    this.remPopularButtonCommands.push(command);
                }
            } else {
                index = this.addPopularButtonCommands.indexOf(command);
                if (index > -1) {
                    this.addPopularButtonCommands.splice(index, 1);
                } else {
                    this.addPopularButtonCommands.push(command);
                }
            }
        },

        handleOkClick: function (e) {
            // For each command on add list, add button
            for (let aa = 0; aa < this.addPopularButtonCommands.length; aa += 1) {
                const command = this.addPopularButtonCommands[aa];
                const button = this.findPopularButtonByCommand(command);
                const buttonCopy = JSON.parse(JSON.stringify(button)); // Deep copy
                this.rightCommands.push(buttonCopy);
            }

            // For each command on remove list, remove button
            for (let rr = 0; rr < this.remPopularButtonCommands.length; rr += 1) {
                const command = this.remPopularButtonCommands[rr];
                const buttonPosition = this.findRightButtonPositionByCommand(command);
                if (buttonPosition > -1) {
                    this.rightCommands.splice(buttonPosition, 1);
                }
            }

            ar.setState({
                seaButtonsData: JSON.parse(JSON.stringify(this.rightCommands)),
                menuOpen: false,
            });
            ar.saveSeaButtonsData();
            arRouter.push('/my-remote-config');
        },

        handleCancelClick: function () {
            arRouter.push('/my-remote')
        },

        handlePopularButtonCategoryClick: function (cat) {
            this.currentPopularButtonCategory = cat.category;
        },

        isCategoryActive: function (cat) {
            return cat.category === this.currentPopularButtonCategory;
        },
    },
    computed: {
        buttonsInCurrentCategory: function () {
            if (!this.currentPopularButtonCategory) {
                this.currentPopularButtonCategory = 'Apps';
            }
            const currentCats = this.globalState.popularSeaButtons.filter((cat) => {
                return cat.category === this.currentPopularButtonCategory;
            });
            if (currentCats && currentCats.length > 0) {
                return currentCats[0].buttons;
            }
            return []; // Ugh
        },
    },
    mounted: function () {
        this.$nextTick(function () {
            const self = this;

            ar.setState({
                customMode: true,
            });
            if (ar.isLocalStorageAvailable('localStorage')) {
                ar.saveLocal('customMode', 'true');
            }

            // Catch for popular buttons on app init.
            ar.getPopularSeaButtons();

            // Fill right list of selected custom buttons, fetching if necessary (not typical)
            if (!this.globalState.haveSeaButtonsData) {
                ar.getSeaButtonsData(function () {
                    if (ar.state.seaButtonsData.length > 0) {
                        self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
                        self.originalRightCommandsJson = JSON.stringify(ar.state.seaButtonsData); // Deep copy
                    }
                });
            } else {
                if (ar.state.seaButtonsData.length > 0) {
                    self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
                    self.originalRightCommandsJson = JSON.stringify(ar.state.seaButtonsData); // Deep copy
                    self.resetPopularButtonsLists();
                }
            }
        });
    },
};
</script>
<style lang="scss" scoped>
#choose-popular-buttons-dialog-main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .choose-popular-buttons-group {
        @include lg {
            width: 80rem;
            max-width: 95%;
        }
    }

    .choose-popular-buttons-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: $xfinity-grey-dark;
    }

    .choose-popular-buttons-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: $xfinity-grey-dark;
        font-size: 1.25rem;
        min-height: 4rem;

        @include lg {
            min-height: 5.5rem;
        }
    }

    #choose-popular-buttons-tabs {
        margin: 0;
        overflow: scroll;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        width: 100%;

        ul {
            flex-direction: row;
            justify-content: space-evenly;
            background-color: $xfinity-grey-dark;
            list-style-type: none;
            max-height: 12.8125rem;
            overflow-y: auto;
            margin: 0;
            padding: 0;
            flex-wrap: wrap;

       
            &::-webkit-scrollbar {
                width: 0.5rem;
            }

            &::-webkit-scrollbar-track {
                background-color: rgb(162, 155, 155);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgb(224, 217, 217);
                border-radius: 10px;
            }

            @include lg {
                display: flex;
                overflow: visible;
                justify-content: flex-start;
            }
        }

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }

        @include lg {
            width: 80rem;
            max-width: 95%;
        }
    }

    .popular-button-tab {
        text-align: center;
        font-family: 'XfinityBrown-Bold', sans-serif;
        font-size: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: white;
        cursor: pointer;

        &.active-popular-button-tab {
            background-color: $ar-background-color;

            @include lg {
                border-radius: 0.5rem 0.5rem 0 0;
            }
        }

        &:hover,
        &:focus {
            @include tab-focus-outline;

            @include lg {
                border-radius: 0.5rem 0.5rem 0 0;
                border-bottom-color: white;
                &.active-popular-button-tab {
                    border-bottom-color: white;
                }
            }
        }

        @include lg {
            line-height: 1.5rem;
            padding-left: 2.25rem;
            padding-right: 2.125rem;
            border-bottom: 0.125rem solid $xfinity-grey-dark;
            &.active-popular-button-tab {
                border-bottom-color: $ar-background-color;
            }
        }
    }

    #choose-popular-buttons-buttons {
        display: grid;
        grid-template-columns: auto auto auto;
        width: 19.75rem;
        grid-gap: 0.5rem;
        margin-top: 1.75rem;

        @include lg {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
        }
    }

    #choose-popular-buttons-actions {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}
</style>
