<template>
	<div id="edit-button-order-page" class="page">
		<div id="seabuttons-config" class="">
			<template v-if="globalState.menuOpen">
				<div id="blockScreen" class="block-screen" @click="closeMenu"></div>
			</template>
			<Menu></Menu>
			<RemoteHeader :heading="headingLabel"></RemoteHeader>

			<EditCustomButton
				v-if="showEditCustomButton"
				:custom-button-add-mode="customButtonAddMode"
				:current-right-command="currentRightCommand"
				:right-commands="rightCommands"
				@update-custom-button-event="updateCustomButtonEvent"
				@update-show-custom-button="updateShowCustomButton"
				@update-add-custom-clicked="updateAddCustomClicked"
			/>

			<AddNewButton
				v-if="isAddCustomClicked && !showEditCustomButton"
				@update-show-custom-button="updateShowCustomButton"
				@update-custom-button-add-mode="updateCustomButtonAddMode"
			/>

			<div
				v-if="!showEditCustomButton && !isAddCustomClicked"
				id="seabuttons-config-main"
				class="seabuttons-config"
			>
				<h3 class="heading-secondary">
					{{ $t('editButtonOrderPageYourFirstButtons') }}
				</h3>
				<p v-if="!globalState.haveSeaButtonsData || globalState.isFetchingSeaButtons">
					{{ $t('editButtonOrderPagePleaseWait') }}...
				</p>
				<div v-else class="custom-buttons-group" id="">
					<template v-if="!rightCommands || rightCommands.length === 0">
						<p>{{ $t('editButtonOrderPageNoneSelectedYet') }}.</p>
						<p>{{ $t('editButtonOrderPageFtueHelp') }}</p>
					</template>
					<div
						v-else
						class="custom-buttons-section"
						id="top-buttons-section"
						role="listbox"
					>
						<template v-for="(seaButton, i) in rightCommands">
							<template v-if="i === topButtonsNum && setButtonIndex(i)"></template>
							<button
								v-if="i <= topButtonsNum"
								class="btn command-button"
								role="option"
								:ref="`seaButton${i}`"
								:tabindex="i > 0 ? '-1' : '0'"
								:aria-selected="String(isActive(seaButton))"
								@click="handleRightClick($event, seaButton, i)"
								@keydown.up.prevent="handleUpKey"
								@keydown.down.prevent="handleDownKey"
								:data-command="seaButton.command"
								:class="{
									'seabuttons-config-item-active': isActive(seaButton)
								}"
								:key="i"
							>
								<img
									:class="{ 'soft-img': seaButton.isLegacyIcon }"
									:alt="''"
									:src="safeImage(seaButton.image)"
								/>
								<label
									class="button-description-cell button-description-value"
									v-html="seaButton.displayString"
								>
								</label>
							</button>
						</template>
					</div>
				</div>
				<div class="seabuttons-config-lower-action">
					<template v-if="buttonIndex === topButtonsNum">
						<div
							class="custom-buttons-gradient"
							:class="{ 'one-page-buttons': disableScroll }"
						>
							<div class="custom-buttons-scroll" role="listbox">
								<template v-for="(seaButton, i) in rightCommands">
									<button
										v-if="i > topButtonsNum"
										class="btn command-button"
										role="option"
										:ref="`seaButton${i}`"
										:tabindex="i > topButtonsNum + 1 ? '-1' : '0'"
										:aria-selected="String(isActive(seaButton))"
										@click="handleRightClick($event, seaButton, i)"
										@keydown.up.prevent="handleUpKey"
										@keydown.down.prevent="handleDownKey"
										:data-command="seaButton.command"
										:class="{
											'seabuttons-config-item-active': isActive(seaButton)
										}"
										:key="i"
									>
										<img
											:class="{ 'soft-img': seaButton.isLegacyIcon }"
											:alt="''"
											:src="safeImage(seaButton.image)"
										/>
										<label
											class="button-description-cell button-description-value"
											v-html="seaButton.displayString"
										>
										</label>
									</button>
								</template>
							</div>
						</div>
					</template>

					<div class="seabuttons-config-order-dpad" v-if="rightCommands.length > 0">
						<button
							@click="handleScrollUp"
							aria-label="Scroll Up"
							:disabled="disableScroll"
						>
							<img src="/images/icons/pageUp.svg" alt="" role="img" aria-hidden="true" />
							<label>Scroll up</label>
						</button>
						<button
							:disabled="isNavDisabledFirst"
							:aria-disabled="isNavDisabledFirst"
							v-on="
								isOKToggled
									? { click: (e) => handleMove(e, getDirection(directionUp)) }
									: { click: (e) => handleFocus(e, getDirection(directionUp)) }
							"
							aria-label="Up"
						>
							<img class="up-arrow" src="/images/icons/upArrow.svg" alt="" role="img" aria-hidden="true" />
						</button>
						<button
							aria-label="Delete command"
							:disabled="isDeleteDisabled"
							:aria-disabled="isDeleteDisabled"
							v-if="isDeleteVisible"
							@click="handleDeleteClick"
						>
							<img class="img-mt-1" src="/images/icons/icon_trash_outline.svg" alt="" role="img" aria-hidden="true" />
							<label>{{ $t('editButtonOrderPageDeleteItem') }}</label>
						</button>
						<button
							:disabled="isNavDisabledFirst"
							:aria-disabled="isNavDisabledFirst"
							v-on="
								isOKToggled
									? { click: (e) => handleMove(e, getDirection(directionLeft)) }
									: { click: (e) => handleFocus(e, getDirection(directionLeft)) }
							"
							aria-label="Left"
						>
							<img class="left-arrow" src="/images/icons/leftArrow.svg" alt="" role="img" aria-hidden="true" />
						</button>
						<button
							:class="{ toggled: isOKToggled }"
							aria-label="OK toggle"
							@click="handleOKToggle"
						>
							<span class="ok-button">OK</span>
						</button>
						<button
							:disabled="isNavDisabledLast"
							:aria-disabled="isNavDisabledLast"
							v-on="
								isOKToggled
									? { click: (e) => handleMove(e, getDirection(directionRight)) }
									: { click: (e) => handleFocus(e, getDirection(directionRight)) }
							"
							aria-label="Right"
						>
							<img class="right-arrow" src="/images/icons/rightArrow.svg" alt="" role="img" aria-hidden="true" />
						</button>
						<button
							@click="handleScrollDown"
							aria-label="Scroll Down"
							:disabled="disableScroll"
						>
							<img src="/images/icons/pageDown.svg" alt="" role="img" aria-hidden="true" />
							<label>Scroll down</label>
						</button>
						<button
							:disabled="isNavDisabledLast"
							:aria-disabled="isNavDisabledLast"
							v-on="
								isOKToggled
									? { click: (e) => handleMove(e, getDirection(directionDown)) }
									: { click: (e) => handleFocus(e, getDirection(directionDown)) }
							"
							aria-label="Down"
						>
							<img src="/images/icons/downArrow.svg" alt="" role="img" aria-hidden="true" />
						</button>
						<button @click="handleAddClick">
							<img
								class="add-button"
								src="/images/icons/icon_addcircle_outline.svg"
								alt=""
								role="img"
								aria-hidden="true"
							/>
							<label>{{ $t('editButtonOrderPageAddItem') }}</label>
						</button>
					</div>
				</div>
				<div class="finish-actions">
					<button class="standard-button primary" @click="handleSaveButtonClick">
						{{ $t('editButtonOrderPageSave') }}
					</button>
					<button class="standard-button secondary" @click="handleCancelButtonClick">
						{{ $t('editButtonOrderPageCancel') }}
					</button>
					<button
						class="text-command-button standard-button ghost"
						aria-label="Edit custom button"
						v-if="isEditVisible"
						v-bind:disabled="isEditDisabled"
						v-bind:aria-disabled="isEditDisabled"
						v-on:click="handleCustomClick"
					>
						{{ $t('editButtonOrderPageEditItem') }}
					</button>
				</div>
				<div class="up-down-action-buttons" v-if="isUpDownVisible"></div>
			</div>
			<!-- #seabuttons-config-main -->
		</div>
		<!-- #seabuttons-config -->
	</div>
</template>

<script>
import RemoteHeader from '@/components/RemoteHeader.vue';
import editButtonsMixin from '@/components/mixins/editButtons';
import EditCustomButton from '@/components/EditCustomButton.vue';
import AddNewButton from '@/components/AddNewButton.vue';
import Menu from '@/components/Menu.vue';

export default {
	name: 'EditButtonOrderPage',
	mixins: [editButtonsMixin],
	components: {
		RemoteHeader,
		EditCustomButton,
		AddNewButton,
		Menu
	},
	data: function () {
		return {
			customButtonAddMode: false,
			currentRightCommand: null,
			rightCommands: [],
			showEditCustomButton: false
		};
	},
	methods: {
		closeMenu: function () {
			if (this.globalState.menuOpen) {
				this.globalState.menuOpen = false;
			}
		}
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleWindowResize);
	},
	mounted: function () {
		const self = this;

		this.$nextTick(() => {
			window.addEventListener('resize', this.handleWindowResize);
			Object.keys(this.$refs).length && this.$refs.seaButton0[0].click();
		});

		ar.setState({
			customMode: true
		});
		if (ar.isLocalStorageAvailable('localStorage')) {
			ar.saveLocal('customMode', 'true');
		}

		// Catch for popular buttons on app init.
		ar.getPopularSeaButtons();

		// Fill right list of selected custom buttons, fetching if necessary (not typical)
		if (!this.globalState.haveSeaButtonsData) {
			ar.getSeaButtonsData(function () {
				if (ar.state.seaButtonsData.length > 0) {
					self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
					self.originalRightCommandsJson = JSON.stringify(ar.state.seaButtonsData); // Deep copy
				}
			});
		} else {
			if (ar.state.seaButtonsData.length > 0) {
				self.rightCommands = JSON.parse(JSON.stringify(ar.state.seaButtonsData)); // Deep copy
				self.originalRightCommandsJson = JSON.stringify(ar.state.seaButtonsData); // Deep copy
			}
		}
		window.focusPageHeading();
		window.setPageTitle('editButtonOrderPageHeader');
	}
};
</script>

<style lang="scss" scoped>
#edit-button-order-page {
	height: 100vh;
}
/* ---------- */

#seabuttons-config {
	display: flex;
	flex-direction: column;
}

#seabuttons-config-main {
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
	margin-left: 2rem;
	margin-right: 2rem;

	@include lg {
		align-items: flex-start;
	}
}

.error {
	color: red;
	font-family: XfinityStandard-bold;
}

.add-or-edit-button-info-form {
	display: flex;
	flex-direction: column;
}

#command[aria-invalid='true'] {
	border: 2px solid red;
}

#imagePreview {
	display: block;
	margin-top: 8px;
	background-color: black;
}

.heading-secondary {
	margin-bottom: 1.5rem;
}

.custom-buttons-group {
	display: flex;
	flex-direction: column;
}

.custom-buttons-section {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: auto auto auto auto;
	max-width: 26.75rem;
	position: relative;

	@include lg {
		max-width: 53.5rem;
		grid-template-columns: auto auto auto auto auto auto auto auto auto;
	}
}

.custom-buttons-gradient {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		width: 20.75rem;
		height: 1.75rem;
		z-index: 1;
		box-shadow: inset 0px 1.75rem 1rem -1rem $xfinity-grey-75;

		@include lg {
			width: 41.5rem;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 20.75rem;
		height: 1.75rem;
		box-shadow: inset 0px -1.75rem 1rem -1rem $xfinity-grey-75;

		@include lg {
			width: 41.5rem;
		}
	}

	@include md {
		float: left;
	}
}

.one-page-buttons {
	&:before {
		display: none !important;
	}

	&:after {
		display: none !important;
	}
}

.custom-buttons-scroll {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: auto auto auto;
	max-height: 19.75rem;
	overflow-y: auto;
	position: relative;

	&::-webkit-scrollbar {
		width: 0.5rem;
	}

	&::-webkit-scrollbar-track {
		background-color: rgb(162, 155, 155);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(224, 217, 217);
		border-radius: 10px;
	}

	@include lg {
		padding: 0.5rem 7.25rem 0.5rem 0.5rem;
		grid-template-columns: auto auto auto auto auto auto;
		overflow-x: hidden;
	}
}

.text-command-button {
	margin-top: 2rem;

	@include lg {
		margin-top: 0;
	}
}
.seabuttons-config-lower-action {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.5rem;
	border-top: 1px solid black;
	padding-top: 1.5rem;

	@include md {
		display: block;
		width: 100%;
	}
}

.seabuttons-config-order-dpad {
	display: grid;
	gap: 0.5rem;
	grid-template-columns: auto auto auto;
	width: 19.75rem;
	height: 19.75rem;
	margin-top: 1.75rem;

	button {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		width: 100px;
		height: 100px;
		border-radius: 0.5rem;
		border: none;
		background-color: black;
		color: white;
		font-family: 'XfinityBrown-Bold';
		cursor: pointer;

		.img-mt-1 {
			margin-top: 1rem;
		}

		.add-button {
			margin-top: 0.5rem;
			width: 3.5rem;
		}

		.ok-button {
			display: flex;
			align-self: center;
			font-size: 2rem;
		}

		label {
			font-size: 0.8125rem;
			border-radius: 0 0 0.5rem 0.5rem;
			position: absolute;
			bottom: 0;
			padding: 0.5rem;
		}

		&:hover:enabled,
		&:focus:enabled {
			@include focus-outline;
		}

		&:active:enabled {
			background: $xfinity-grey-15;
			box-shadow: inset 0px 0px 5px $xfinity-grey-25;
		}

		&:disabled {
			&:after {
				content: '';
				border-radius: 0.5rem;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $ar-background-color;
				opacity: 0.5;
			}

			&:hover {
				outline: none;
				cursor: default;
			}
		}
	}

	@include md {
		float: right;
		margin-top: 0;
	}
}

.finish-actions {
	margin-top: 1.25rem;
	align-self: flex-start;
}

.up-down-action-buttons {
	display: flex;
	flex-direction: column;
	margin-top: 2.5rem;
}

.command-button {
	img {
		margin-bottom: 2rem;
	}
}

.toggled {
	/*
	outline: none !important;
	background-color: $xfinity-grey-dark !important;
	box-shadow: inset 0.125rem 0.125rem 0.5rem 0.25rem $xfinity-grey-darker;
	*/
}

.adhoc-image-container {
	display: flex;
	justify-content: space-evenly;
}

.adhoc-image-link {
	padding: 0;
	display: inline-block;
}

.adhoc-image {
	vertical-align: middle;
}
</style>
