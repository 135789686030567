<template>
    <button id="XFINITY" name="XFINITY" class="btn logo-img" :aria-label="$t('menuLabel')" data-vcode="MENU">
        <img class="" v-bind:src="imgLogo" alt="" role="img" aria-hidden="true" />
        <label>{{ $t('menuButton') }}</label>
    </button>
</template>
<script>
import imgLogo from '../mixins/imgLogo';

export default {
    mixins: [imgLogo],
};
</script>
