<template>
	<div id="ar-settings-page" class="page">
		<Settings></Settings>
	</div>
</template>

<script>
import Settings from '@/components/Settings.vue';

export default {
	name: 'SettingsPage',
	components: {
		Settings
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {}
};
</script>


<style scoped>
</style>