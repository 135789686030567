<template>
	<div id="add-buttons-main">
		<div class="add-button-cards">
			<div class="add-card">
				<p>{{ $t('editButtonOrderPageAddPopularLabel') }}</p>
				<button class="standard-button primary" @click="handleAddPopularClick">
					{{ $t('editButtonOrderPageAddPopularItems') }}
				</button>
			</div>
			<div class="add-card">
				<p>{{ $t('editButtonOrderPageAddCustomLabel') }}</p>
				<button class="standard-button primary" @click="handleShowCustomClick">
					{{ $t('editButtonOrderPageAddCustomItems') }}
				</button>
			</div>
		</div>
		<div class="save-button-area">
			<button class="standard-button text-command-button" @click="handleCancelButtonClick">
				{{ $t('editButtonOrderPageCancel') }}
			</button>
		</div>
	</div>
</template>

<script>
import editButtonsMixin from '@/components/mixins/editButtons';

export default {
	name: 'AddNewButton',
	mixins: [editButtonsMixin],
	methods: {
		handleShowCustomClick: function () {
			this.$emit('update-custom-button-add-mode', true);
			this.$emit('update-show-custom-button', true);
			this.$route.name !== 'editButtonOrderPage' && arRouter.push('/edit-custom-button');
		}
	}
};
</script>

<style lang="scss" scoped>
#add-buttons-container {
	display: flex;
	align-items: center;
	flex-direction: column;
}

#add-buttons-page {
	height: 100vh;
}

#add-buttons-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	margin-top: 4rem;

	@include lg {
		margin-top: 12rem;
	}
}
.add-button-cards {
	display: flex;
	flex-direction: column;
	.add-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: $xfinity-grey-70;
		padding: 2rem 4rem 2rem 4rem;
		border-radius: 0.5rem;
		max-width: 19.75rem;
		margin-bottom: 2rem;

		p {
			max-width: 12.75rem;
			margin-bottom: 3.25rem;
			line-height: 1.5rem;
		}

		button {
			max-width: 12.75rem;
			margin-right: 0;
		}

		@include lg {
			margin-right: 6rem;
		}
	}

	@include lg {
		flex-direction: row;
	}
}
</style>
