<template>
	<div id="choose-device">
		<img class="svg partner-logo" v-bind:src="imgLogo" />

		<template v-if="devices && devices.length > 0">
			<p role="heading" aria-level="1" id="choose-device-heading"  class="standard-heading">
				{{ $t('selectATvBoxHeader') }}
			</p>

			<p id="choose-device-text" class="standard-text">
				{{ $t('selectATvBox') }}
			</p>

			<div id="choose-device-buttons">
				<button v-for="device in devices" v-bind:key="device.id"
						v-bind:data-deviceId="device.id"
						v-bind:data-deviceName="device.name"
						class="choose-device-button standard-button primary" v-on:click="handleDeviceClick">
					{{device.name}}
				</button>
			</div>
		</template>
		<template v-else>
			<p id="choose-device-heading" v-t="'fetchingAccountInformation'" />
		</template>
	</div>
</template>

<script>
import { Analytics } from '../analytics.js';

export default {
	name: 'ChooseDevice',
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {
		handleDeviceClick: function (evt) {
			// Could track device names, but doesn't seem particularly valuable
			Analytics.track({ eventName: 'choose_device', properties: undefined });

			const deviceId = evt.target.dataset.deviceid; // Note the lowercase 'i'!
			const deviceName = evt.target.dataset.devicename; // Note the lowercase 'i'!
			ar.setState({
				selectedXboDeviceId : deviceId,
				selectedXboDeviceName : deviceName,
				menuOpen : false
			});
			ar.saveLocal('deviceId', deviceId);
			ar.saveLocal('deviceName', deviceName);
			ar.getARToken(); // Will go to /remote if successful
		}
	},
	computed: {
		imgLogo: function() {
			return ar.partnerLogoUrl(this.partner);
		}
	},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('selectATvBoxHeader');
	}
};
</script>

<style lang="scss" scoped>
#choose-device {
	display: flex;
	flex-direction: column;
	align-items: center;
	
	& #choose-device-heading {
		padding-left: 10%;
		padding-right: 10%;
	}
}

#choose-device-buttons {
	margin-top: 4rem;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 80%;
	row-gap: 1rem;
}

</style>
