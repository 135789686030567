<template>
    <button v-show="onSeaButtonsPage" class="btn" aria-label="Edit custom button" v-if="isEditVisible"
        @click="handleEditPopularClick">
        <img class="soft-img" src="/images/icons/edit.svg" alt="" role="img" aria-hidden="true" />
        <label>{{ $t('editButtonOrderPageEditItem') }}</label>
    </button>
</template>
<script>
import editButtonsMixin from '../mixins/editButtons'

export default {
    mixins: [editButtonsMixin],
};
</script>
