<template>
	<div class="extra-row-voice-text">
		<div id="voice-text-entry-area">
			<div id="voice-text-entry-icon-and-input">
				<img id="voice-text-entry-icon" name="voice-text-entry-icon" src="/images/icons/voice.svg"
					alt="" />
				<input id="voice-text-entry" name="voice-text-entry" type="text" v-bind:aria-label="$t('typeAVoiceCommand')"
					v-bind:placeholder="$t('typeAVoiceCommand')" size="40" v-model="textCommand"
					v-on:keyup.enter="submitTextInput" />
			</div>
			<input id="voice-text-go-button" name="voice-text-go-button" type="button"
				value="Go" v-on:click="submitTextInput" />
		</div>
	</div>
</template>
<script>
import { Analytics } from '../analytics.js';
import remoteMixin from './mixins/remote';

export default {
	mixins: [remoteMixin],
	methods: {
		submitTextInput: function (ev) {
			// Turn off recording just in case user thought she should hit the
			// Voice Search button before using the text entry feature
			if (ar.recorder.isRecording()) {
				ar.recorder.stop(function (err, url) {
					ar.setState({
						isRecording: false,
					});
				});
			}

			// Submit the text command
			Analytics.track({ eventName: 'adhoc_nlp', properties: { command: this.textCommand } });
			ar.submitTextCommand(this.textCommand);
			// Reset the text command input field
			ar.setState({
				textCommand: '',
			});
		},
	},
};
</script>

<style lang="scss" scoped>
#voice-text-entry-area {
	display: flex;
}

#voice-text-entry-icon-and-input {
	display: flex;
	align-items: center;
	background-color: black;
	height: 2.5rem;
	border-radius: 0.5rem;
	margin-right: 0.5rem;

	&:hover,
	&:focus-within {
		@include focus-outline;
	}
}

#voice-text-entry-icon {
	position: absolute;
	height: 2rem;
	width: 2rem;
	margin-top: 0.5rem;
	margin-left: 0.25rem;
}

#voice-text-entry {
	background-color: black;
	height: 2.25rem;
	color: white;
	border: 0;
	border-radius: 0 0.5rem 0.5rem 0;
	padding: 0;
	margin-left: 2.5rem;
	width: 14rem;
	font-size: 1rem;
	font-family: "XfinityBrown-Bold", Arial;
	
	&:focus {
		// We're showing the focus indicator on the entire control, not just the input field.
		outline: none;
	}

	&::placeholder {
		color: white;
		opacity: 1;
		/* Firefox */
	}

}

#voice-text-go-button {
	background-color: black;
	color: white;
	border: 0;
	border-radius: 0.5rem;
	padding: 0;
	width: 2.5rem;
	height: 2.5rem;
	font-size: 1rem;
	font-family: "XfinityBrown-Bold", Arial;

	&:hover,
	&:focus {
		@include focus-outline;
	}
}
</style>
