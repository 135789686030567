import { render, staticRenderFns } from "./FlexRemote.vue?vue&type=template&id=42c8c575&scoped=true"
import script from "./FlexRemote.vue?vue&type=script&lang=js"
export * from "./FlexRemote.vue?vue&type=script&lang=js"
import style0 from "./FlexRemote.vue?vue&type=style&index=0&id=42c8c575&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c8c575",
  null
  
)

export default component.exports