<template>
	<div id="tvconf-eventname-container">
		<div class="tvconf-eventname-form">
			<h2>{{ $t('tvConfigPageEventHeading') }}</h2>
			<p>{{ $t('tvConfigPageEventSubHeading') }}</p>
			<div id="eventname-group">
				<div class="eventname-item" v-for="tvButton in tvButtons()" v-bind:key="tvButton.key">
					<div class="eventname-form-group" :class="{ 'border-none': isFocused(tvButton.key) }">
						<label class="eventname-label" :for="`input-${tvButton.key}`">{{ tvButton.label }}</label>
						<input
							:ref="tvButton.key"
							:id="`input-${tvButton.key}`"
							class="eventname-input"
							name="event-name"
							type="text"
							@change="handleEventNameChange"
							v-model="tvButtonsEvents[tvButton.key]"
							@click.stop="setFocus(tvButton.key)"
						/>
					</div>
					<div class="eventname-button-group">
						<!-- <button
							class="eventname-button standard-button"
							v-show="!isFocused(tvButton.key)"
							@click.stop="setFocus(tvButton.key)"
						>
							{{
								tvButtonsEvents[tvButton.key]
									? $t('tvConfigPageConfigEdit')
									: $t('tvConfigPageConfigSetup')
							}}
						</button> -->
						<button
							class="eventname-button standard-button primary"
							@click.stop="handleTestButtonClick(tvButton.key, tvButton.label)"
							:aria-label="`${$t('tvConfigPageConfigTest')} ${tvButton.label}`"
						>
							{{ $t('tvConfigPageConfigTest') }}
						</button>
						<button
							class="eventname-button standard-button secondary"
							@click.stop="handleClearButtonClickEvent(tvButton.key, tvButton.label)"
							:aria-label="`${$t('tvConfigPageConfigClear')} ${tvButton.label}`"
						>
							{{ $t('tvConfigPageConfigClear') }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<Announcer :message="announcerMessage"></Announcer>
	</div>
</template>

<script>
import tvConfigMixins from '../components/mixins/tvConfig';
import Announcer from './Announcer.vue';
export default {
	props: ['apiKey', 'tvButtonsEvents', 'hasFocus'],
	name: 'TVConfigEventName',
	mixins: [tvConfigMixins],
	components: {
		Announcer
	},
	data: function () {
		return {
			showButtons: false,
			announcerMessage: ""
		};
	},
	methods: {
		toggleButtons: function () {
			return this.showButtons = true;
		},
		isFocused: function (buttonKey) {
			return this.hasFocus[buttonKey];
		},
		isButtonDisabledEvent: function (buttonKey) {
			return !this.tvButtonsEvents[buttonKey];
		},
		setFocus: function (buttonKey) {
			for (const property in this.hasFocus) {
				property === buttonKey
					? (this.hasFocus[property] = true)
					: (this.hasFocus[property] = false);
			}
			this.$emit('update-has-focus', this.hasFocus);
			this.$refs[buttonKey][0].focus();
			return;
		},
		handleEventNameChange: function () {
			this.$emit('update-buttons-events', this.tvButtonsEvents);
		},
		handleClearButtonClickEvent: function (buttonKey, buttonLabel) {
			let msg = `Cleared command for ${buttonLabel} button`;
			if (this.announcerMessage === msg) {
				msg = msg + " ";
			}
			this.announcerMessage = msg;
			this.tvButtonsEvents[buttonKey] = '';
			this.$emit('update-buttons-events', this.tvButtonsEvents);
		},
		handleTestButtonClick: function (buttonKey, buttonLabel) {
			let msg = `Testing command for ${buttonLabel} button`;
			if (this.announcerMessage === msg) {
				msg = msg + " ";
			}
			this.announcerMessage = msg;
			this.tvConfigData[buttonKey] = { eventName: this.tvButtonsEvents[buttonKey] };
			this.tvConfigData.apiKey = this.apiKey;
			ar.processIotCommand(buttonKey, this.tvConfigData);
		},
	},
	mounted: function() {
		window.focusPageHeading("h2");
	}
};
</script>

<style lang="scss" scoped>
#ar-tvconfig-page {
	.tvconf-eventname-form {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 2.5rem;

		#eventname-group {
			display: flex;
			flex-direction: column;
			align-items: center;

			@include lg {
				display: grid;
				grid-template-columns: 50% 50%;
			}
		}

		.eventname-item {
			display: flex;
			align-items: flex-end;
			margin-bottom: 1.25rem;
			flex-direction: column;

			@include lg {
				flex-direction: row;
			}
		}

		.eventname-form-group {
			display: flex;
			flex-direction: column;
			border-bottom: 1px white solid;
			width: 16.25rem;

			@include lg {
				margin-right: 0.75rem;
			}
		}

		.eventname-button {
			margin-right: 0;

			&:nth-child(2) {
				margin-right: 1rem;
			}
		}

		.eventname-button-group {
			display: flex;
			gap: 0.75rem;
			margin-top: 0.5rem;
			margin-right: 1rem;

			@include lg {
				width: 14rem;
			}
		}

		.eventname-label {
			font-size: 1rem;
			line-height: 2rem;
		}

		.eventname-value {
			font-family: 'DMSans-Regular';
			height: 3.5rem;
			display: flex;
			align-items: center;
		}

		.eventname-input {
			height: 3.5rem;
			border: 0;
			padding: 0;
			text-indent: 1rem;
			border-radius: 0.25rem;
			font-size: 1rem;
			width: 16.25rem;
			color: $xfinity-grey-light;
			background-color: $xfinity-grey-lighter;
			@include focus-pseudo-class;
		}

		p {
			font-family: 'XfinityBrown-Regular';
			font-size: 1.25rem;
			margin-bottom: 3.25rem;
		}
	}
}
</style>
