import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import getPartnerMessages from '@/partnerMessages';
const partner = ar.getPartnerFromUrl();
const corePartner = ar.getCorePartnerFromPartner(partner);
const messages = getPartnerMessages(corePartner);

const i18n = new VueI18n({
	locale: ar.getDefaultLocaleFromUrl(),
	fallbackLocale: 'en',
	messages
});

// Restore locale from local storage if saved there
if ( ar.isLocalStorageAvailable('localStorage') ) {
	const locale = ar.getLocal('locale');
	if ( locale ) {
		i18n.locale = locale;
	}
}

// Stash for manipulation within "regular" JavaScript (outside of VueJS)
// Specifically, ar.setI18nLocale() defined in AccessibleRemote.js
ar.i18n = i18n;

export default i18n;
