<template>
	<div id="msg-container" v-if="msg">
		<span id="msg">{{msg}}</span>
		<span id="msg-close" v-on:click="hideMsg" v-on:keydown.enter="hideMsg" v-on:keydown.space.prevent="hideMsg" role="button" tabindex="0">&#x2716;</span>
	</div>
</template>

<script>
export default {
	name: 'Msg',
	props: [ 'msg' ],
	data: function() { return {}; },
	methods: {
		hideMsg: function(event) {
			ar.clearMessage();
		}
	},
	computed: {}
};
</script>

<style scoped>
</style>
