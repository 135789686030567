<template>
	<div id="ar-waiting-for-tv-page" class="page">
		<WaitingForTv></WaitingForTv>
	</div>
</template>

<script>
import WaitingForTv from '@/components/WaitingForTv.vue';

export default {
	name: 'WaitingForTvPage',
	components: {
		WaitingForTv
	},
	props: [],
	data: function() {
		return ar.state;
	},
	methods: {},
	computed: {},
	mounted: function() {
		window.focusPageHeading();
		window.setPageTitle('continueSetupWithEasyPair');
	}
};
</script>

<style scoped>
</style>